/* Normal desktop :1200px. */
@media (min-width: 1500px) and (max-width: 1650px) {


.change-appointment-position {
    right: 190px !important;
}


}
/* Normal desktop :1200px. */
@media (min-width: 1365px) and (max-width: 1500px) {


.change-appointment-position {
    right: 190px !important;
}











}


/* Normal Laptop :992px. */
@media (min-width: 992px) and (max-width: 1199px) {

.header-menu ul li a {
	margin: 23px 3px!important;
}
.dreamit-section-main-title h2, .dreamit-section-main-title h3 {
    font-size: 33px!important;
}
.header-area .sub-menu ul li {
    margin-bottom: -71px!important;
}
.blog-meta-box span {
    margin-right: 8px!important;
}
.change-appointment-position {
    right: -4px!important;
}
.dreamit-blog-social ul li {
    margin-left: -4px!important;
}
.dreamit-post-tag ul li {
    margin-left: 0px!important;
}
.dreamit-section-text p {
    font-size: 14px;
}






}


/* Tablet Tab :768px. */
@media (min-width: 768px) and (max-width: 991px) {

    /*start Mobile menu*/
 .header-area.sticky-nav{
 position:relative !important;
  }
.header-logo{
    display: none !important;
}
.logo-txt::before {
  display: block !important;
}
.menu-toggle {
  display: block !important;
}
.header-area {
	padding: 10px 30px !important;
}

.header-menu ul li span{
    display: none;
}
.header-menu ul li .mobile-menu-icon {
    display:block !important;
}
.active-nav {
    left:0 !important;
}
.menu-toggle {
    position: absolute;
    right: 30px;
    top: 1px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
}
.header-button {
    display: none !important;
}
.header-menu ul li a {
    display: block !important;
    margin: 0 !important;
    padding: 18px 0 18px 25px;
}
.header-menu ul li{
    border-bottom: 1px solid #ccc;
}
.header-menu ul li:last-Child{
    border:none;
}
.header-menu ul {
    text-align: left;
    display: block !important;
}
.header-menu ul li {
    display: block !important;
    padding-bottom: 0 !important;
}
.mobile-menu-icon {
    color: #6d6d6d;
    right: 0;
    float: right;
    margin-right: 30px;
    display: block !important;
    transition: .3s;
}
.header-menu ul li:hover .mobile-menu-icon {
	color: #279e64;
}
.header-menu .sub-menu {
    position: absolute;
    left: 350px !important;
    padding-bottom: 30px;
}
.header-menu ul li:hover .sub-menu {
    top: 0 !important;
    left: 350px !important;

}

.header-area .sub-menu ul li{
    border-bottom: 1px solid #d6d6d6;
    margin-bottom: 0 !important;
    transition: .5s;
}
.header-area .sub-menu ul li:last-Child{
     border-bottom: none;
}
.header-area .sub-menu ul li a {
    margin: 0 !important;
    padding: 18px 30px !important;
    transition: .5s;
}
.header-area .sub-menu ul li a::before {
    left: 5px !important;
    top: 30px !important;
    transition: .5s;
}
.header-area .sub-menu ul li:hover{
   margin-left: 10px;
}
.header-top-menu {
	display: none!important;
}
.sticky-nav .header-menu ul li a:hover {
	color: #279e64!important;
}
.slider-area.style-two {
	background-size: cover!important;
	background-position: center!important;
}
.slider-area.style-three {
	background-size: cover!important;
	background-position: center!important;
}
#to-top {
    right: 67px!important;
    width: 46px!important;
    height: 46px!important;
}
.dreamit-choose-us-area.style-two .col-lg-6.pl-35 {
    padding-top: 29px!important;
}
.dreamit-why-choose-area.style-three .pt-30 {
    padding-top: 1px!important;
}
.dreamit-why-choose-area.style-three .dreamit-choose-content p {
    color: #fff;
    font-size: 17px;
}
.slider-content h1 {
    font-size: 57px!important;
}
.change-appointment-position {
    display: none!important;
}
.block-quoet-thumb {
    margin-bottom: 22px;
}
.form_box input {
    margin-bottom: 10px!important;
}
.dreamit-service-area.style-four.pb-130 {
    padding-bottom: 67px!important;
}
.process-content::after {
    right: -7px!important;
}
.dreamit-choose-us-area.pb-65 {
    padding-bottom: 24px!important;
}
.priching-area.pt-110 {
    padding-top: 72px!important;
}
.priching-area .pt-25 {
    padding-top: 0px!important;
}
.testimonial-area.pt-95 {
    padding-top: 33px!important;
}
.blog-area.pt-110 {
    padding-top: 64px;
}
.blog-area.pb-130 {
    padding-bottom: 60px!important;
}
.blog-content-text p {
    font-size: 14px!important;
}
.block-quoet p {
    font-size: 18px!important;
}
.clean-thumb img {
    margin-bottom: 30px!important;
}
.house-cleaning-thumb img {
    margin-bottom: 30px!important;
}
.call-do-action.style-two .call-do-content-text p {
    font-size: 17px!important;
}
.transparent.sticky-nav{
position: relative!important;
}
.search-box-btn.search-box-outer i {
    display: none!important;
}
.about-content-text p {
    font-size: 14px!important;
}
.dreamit-single-counter {
    margin-bottom: 30px;
}
.dreamit-bar-thumb {
    padding-top: 28px;
}
.testimonial-area.style-two.pt-110 {
    padding-top: 41px!important;
}
.testimonial-area.style-two.pb-120 {
    padding-bottom: 59px!important;
}
.blog-meta-box span {
    font-size: 10px!important;
    margin-right: 3px!important;
}
.blog-area.style-four .dreamit-blog-content {
    padding: 25px 15px 40px!important;
}
.blog-area.style-four .blog-title h2 {
    font-size: 26px!important;
}
.block-quoet p {
    font-size: 14px!important;
}
.dreamit-house-cleaning-area.style-two .appoinment-form {
    padding: 45px 3px 70px!important;
}
.appoinment-title h3 {
    font-size: 21px!important;
}
.blog-content-text p {
    font-size: 13px!important;
}





}

/* Large Mobile :600px. */
@media only screen and (min-width: 600px) and (max-width: 767px) {


    /*start Mobile menu*/
.header-logo{
    display: none !important;
}
.logo-txt::before {
  display: block !important;
}
.menu-toggle {
  display: block !important;
}
.header-menu ul li span{
    display: none;
}
.header-menu ul li .mobile-menu-icon {
    display:block !important;
}
.active-nav {
    left:0 !important;
}
.menu-toggle {
    position: absolute;
    right: 1px;
    top: 1px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
}
.header-button {
    display: none !important;
}
.header-menu ul li a {
    display: block !important;
    margin: 0 !important;
    padding: 18px 0 18px 25px;
}
.header-menu ul li{
    border-bottom: 1px solid #ccc;
}
.header-menu ul li:last-Child{
    border:none;
}
.header-menu ul {
    text-align: left;
    display: block !important;
}
.header-menu ul li {
    display: block !important;
    padding-bottom: 0 !important;
}
.mobile-menu-icon {
    color: #6d6d6d;
    right: 0;
    float: right;
    margin-right: 30px;
    display: block !important;
    transition: .3s;
}
.header-menu ul li:hover .mobile-menu-icon {
	color: #279e64;
}
.header-menu .sub-menu {
    position: absolute;

    left: 350px !important;
    padding-bottom: 30px;
}
.header-menu ul li:hover .sub-menu {
    top: 0 !important;
    left: 350px !important;  
}

.header-area .sub-menu ul li{
    border-bottom: 1px solid #d6d6d6;
    margin-bottom: 0 !important;
    transition: .5s;
}
.header-area .sub-menu ul li:last-Child{
     border-bottom: none;
}
.header-area .sub-menu ul li a {
    margin: 0 !important;
    padding: 18px 30px !important;
    transition: .5s;
}
.header-area .sub-menu ul li a::before {
    left: 5px !important;
    top: 30px !important;
    transition: .5s;
}
.header-area .sub-menu ul li:hover{
   margin-left: 10px;
}
.header-top-menu {
	display: none!important;
}
.sticky-nav .header-menu ul li a:hover {
}
.slider-area.style-two {
	background-size: cover!important;
	background-position: center!important;
}
.slider-area.style-three {
	background-size: cover!important;
	background-position: center!important;
}
.dreamit-section-main-title h2, .dreamit-section-main-title h3 {
	font-size: 28px!important;
}
.slider-content h1 {
    font-size: 42px!important;
}
.slider-content p {
	width: 84%!important;
}
.dreamit-service-shape {
	top: 39%!important;
}
.pricing-thumb {
	margin-left: 197px!important;
}
#to-top {
    right: 7px!important;
    width: 32px!important;
    height: 46px!important;
}
.change-appointment-position {
    display: none!important;
}
.block-quoet-thumb {
    margin-bottom: 22px;
}
.block-quoet-body ul li {
    margin-left: 0px!important;
}
.blog-area.style-four .dreamit-blog-content {
    padding: 25px 31px 40px!important;
}
.blog-area.pt-110 {
    padding-top: 51px!important;
}
.form_box input {
    margin-bottom: 10px!important;
}
.dreamit-about-area.pt-95 {
    padding-top: 37px!important;
}
.dreamit-choose-us-area.pb-65 {
    padding-bottom: 18px!important;
}
.process-content::after {
    right: 60px!important;
}
.priching-area .pt-25 {
    padding-top: 0px!important;
}
.blog-area .pb-30 {
    padding-bottom: 7px!important;
}
.blog-area.pb-130 {
    padding-bottom: 35px!important;
}
.dreamit-service-area.pt-110 {
    padding-top: 76px!important;
}
.dreamit-contact-content {
    margin-bottom: 16px;
}
.block-quoet p {
    font-size: 16px!important;
}
label {
    font-size: 12px!important;
}
.clean-thumb img {
    margin-bottom: 30px!important;
}
.house-cleaning-thumb img {
    margin-bottom: 30px!important;
}
.transparent.sticky-nav{
position: relative!important;
} 
.search-box-btn.search-box-outer i {
    display: none!important;
}
 .header-area.sticky-nav{
 position:relative !important; 
  }
.dreamit-single-counter {
    margin-bottom: 30px;
}
.dreamit-bar-thumb {
    padding-top: 28px;
}







}

/* small mobile :320px. */
@media only screen and (max-width: 599px) {


    /*start Mobile menu*/
.header-menu ul li a {
	margin: 6px 1px !important;
}
.owl-prev, .owl-next {
	display: none!important;
}
.slider-content h1, 
.slider-content h2 {
	font-size: 24px !important;
}
.slider-content h1 {
    font-size: 22px !important;
}
.slider-area.style-three .slider-content h1 {
    font-size: 22px !important;
}
.slider-content h4 {
	font-size: 13px!important;
}
    /*start Mobile menu*/
.header-logo{
    display: none !important;
}
.logo-txt::before {
  display: block !important;
}
.menu-toggle {
  display: block !important;
}


.header-menu ul li span{
    display: none;
}
.header-menu ul li .mobile-menu-icon {
    display:block !important;
}
.active-nav {
    left:0 !important;
}
.menu-toggle {
    position: absolute;
    right: 1px;
    top: 1px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
}
.header-button {
    display: none !important;
}
.header-menu ul li a {
    display: block !important;
    margin: 0 !important;
    padding: 18px 0 18px 25px;
}
.header-menu ul li{
    border-bottom: 1px solid #ccc;
}
.header-menu ul li:last-Child{
    border:none;
}
.header-menu ul {
    text-align: left;
    display: block !important;
}
.header-menu ul li {
    display: block !important;
    padding-bottom: 0 !important;
}
.mobile-menu-icon {
    color: #6d6d6d;
    right: 0;
    float: right;
    margin-right: 30px;
    display: block !important;
    transition: .3s;
}
.header-menu ul li:hover .mobile-menu-icon {
    color: #ff3c00;
}
.header-menu .sub-menu {
    position: absolute;
    left: 150px !important;
    padding-bottom: 30px;
}
.header-menu ul li:hover .sub-menu {
    top: 0 !important;
    left: 350px !important;
    
}
.header-menu .sub-menu ul{
}
.header-area .sub-menu ul li{
    border-bottom: 1px solid #d6d6d6;
    margin-bottom: 0 !important;
    transition: .5s;
}
.header-area .sub-menu ul li:last-Child{
     border-bottom: none;
}
.header-area .sub-menu ul li a {
    margin: 0 !important;
    padding: 18px 6px !important;
    transition: .5s;
}
.header-area .sub-menu ul li a::before {
    left: 5px !important;
    top: 30px !important;
    transition: .5s;
}
.header-area .sub-menu ul li:hover{
   margin-left: 10px;
}
.header-top-menu {
    display: none!important;
}

.header-menu ul li:hover .sub-menu {
    top: 0 !important;
    left: 186px !important;
}

.header-menu ul li:hover .sub-menu {
    top: 0 !important;
    left: 150px !important;
}

.header-area .sub-menu ul li a {
    font-size: 15px!important;
}
.header-menu ul li:hover .mobile-menu-icon {
	color: #279e64;
}
.slider-area.style-two {
	background-size: cover!important;
	background-position: center!important;
}
.slider-area.style-three {
	background-size: cover!important;
	background-position: center!important;
}
.dreamit-section-main-title h2, .dreamit-section-main-title h3 {
	font-size: 28px!important;
}
.pricing-thumb {
	margin-left: 105px!important;
}
.dreamit-choose-content p {
	font-size: 14px!important;
}
.pb-100 {
	padding-bottom: 10px!important;
}
.dreamit-single-counter {
    margin-bottom: 30px;
}
.slider-content p {
	font-size: 13px!important;
	width: 100%!important;
}
.dreamit-bar-thumb {
    padding-top: 28px;
}
.slider-area {
	height: 545px!important;
}
.dreamit-single-about-counter {
	height: 149px!important;
	width: 149px!important;
	top: 135px!important;
}
.about-counter-title h3 {
	font-size: 16px!important;
}

.dreamit-service-shape {
	top: 23%!important;
}

.slider-button {
    display: none!important;
}
#to-top {
    right: 7px!important;
    width: 32px!important;
    height: 46px!important;
}
.process-content::after {
    right: -3px!important;
  
}
.feature-title h2 {
    font-size: 16px!important;
}
.dreamit-service-area.style-four.pb-130 {
    padding-bottom: 26px!important;
}
.testimonial-area.style-two.pb-120 {
    padding-bottom: 45px!important;
}
.blog-area.pb-80 {
    padding-bottom: 1px!important;
}
.blog-area.style-four .dreamit-blog-content {
    padding: 25px 2px 40px!important;
}
.blog-area.style-four .blog-title h2 {
    font-size: 24px!important;
}
.blog-content-text p {
    font-size: 13px!important;
}
.block-quoet p {
    font-size: 16px!important;
}
.block-quoet-body ul li {
    margin-left: 0px!important;
    font-size: 10px!important;
}
.form_box input {
    margin-bottom: 10px!important;
}
.sidebar-thumb-title a h2 {
    font-size: 12px!important;
}
.sidebar-thumb-title span {
    font-size: 13px!important;
}
.blog-area.style-four.pb-85 {
    padding-bottom: 22px!important;
}
.block-quoet-thumb {
    margin-bottom: 22px;
}
.dreamit-service-area.pt-110 {
    padding-top: 56px!important;
}
.dreamit-service-area .pb-30 {
    padding-bottom: 9px!important;
}
.dreamit-service-area.pb-70 {
    padding-bottom: 23px!important;
}
.dreamit-choose-us-area.pt-100 {
    padding-top: 60px!important;
}
.dreamit-choose-us-area.pb-65 {
    padding-bottom: 2px!important;
}
.dreamit-why-choose-area .pl-0{
    padding-left: 2!important;
}
.dreamit-why-choose-area.pt-60 {
    padding-top: 14px!important;
}
.priching-area.pt-110 {
    padding-top: 59px!important;
}
.priching-area .pt-25 {
    padding-top: 0px!important;
}
.priching-area.pb-80 {
    padding-bottom: 39px!important;
}
.testimonial-area.pt-95 {
    padding-top: 36px!important;
}
.blog-area.pb-130 {
    padding-bottom: 3px!important;
}
.blog-area .pb-30 {
    padding-bottom: 5px!important;
}
.blog-area.pt-110 {
    padding-top: 45px!important;
}
.copy-right-text p {
    font-size: 15px!important;
}
.footer-text-menu ul li a {
    margin-left: 10px!important;
}
.blog-content-text p {
    font-size: 14px!important;
}
.blog-title h2 {
    font-size: 20px!important;
}
.blog-meta-box span {
    margin-right: 7px!important;
}
label {
    font-size: 12px!important;
}
.block-quoet p {
    font-size: 14px!important;
}
.clean-thumb img {
    margin-bottom: 30px!important;
}
.house-cleaning-thumb img {
    margin-bottom: 30px!important;
}
.breatcome-content-title h1 {
    font-size: 36px!important;
}
.transparent.sticky-nav{
position: relative!important;
} 
.search-box-btn.search-box-outer i {
    display: none!important;
}
 .header-area.sticky-nav{
 position:relative !important; 
  }
 .dreamit-single-counter {
    margin-bottom: 30px;
}
.appoinment-form {
    padding: 45px 10px 70px!important;
    margin-right: 0px!important;
}
.dreamit-service-area.style-four.pt-105 {
    padding-top: 42px!important;
}
.service-area.style-five.pt-100 {
    padding-top: 40px!important;
}
.service-area.style-five .dreamit-section-title.style-two.pb-30 {
    padding-bottom: 5px!important;
}
.dreamit-team-area.style-three.pt-90 {
    padding-top: 31px!important;
}
.dreamit-team-area.style-three .dreamit-section-title.pb-30 {
    padding-bottom: 5px!important;
}
.dreamit-blog-social {
    padding-top: 10px;
}
.blog-area.style-four .dreamit-blog-content {
    padding: 25px 2px 14px!important;
}
}

@media (max-width: 390px) {
    .dreamit-section-main-title h2, .dreamit-section-main-title h3 {
        font-size: 28px!important;
    }
}
