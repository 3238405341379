@import 'theme/animate.css';
@import 'theme/animated-text.css';
@import 'theme/jquery-ui.min.css';
@import 'theme/meanmenu.min.css';
@import 'theme/nivo-slider.css';
@import 'theme/owl.carousel.min.css';
@import 'theme/owl.transitions.css';
@import 'theme/responsive.css';
@import 'theme/theme-default.css';
//@import 'theme/uikit.min.css';
@import 'theme/widget.css';

:root {
    --blue: #13287d;
    --darkblue: #13287d;
    --orange: #fd9100;
}

#scrollUp {
    bottom: 30px;
    color: #fff;
    font-size: 34px;
    height: 60px;
    line-height: 60px;
    right: 30px;
    text-align: center;
    width: 60px;
    border-radius: 100%;
    background: #fd9100;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.header-menu {
    text-align: right;
}
.header-top-menu {
    padding: 10px 0;
    background: var(--blue);
    position: relative;
    z-index: 1;
}
.header-top-address a i {
    margin-right: 7px;
    color: #fff;
}
.header-top-address ul {
    list-style: none;
}
.header-top-address ul li {
    display: inline-block;
}
.header-top-address ul li a {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-right: 17px;
    transition: .5s;
}
.header-top-address ul li {
    color: #fff;
}
.header-top-address ul li span {
    margin-right: 25px;
}
.header-top-address ul li span i {
    margin-right: 6px;
}
.hrader-top-social a {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-left: 13px;
    display: inline-block;
    text-align: right;
}

html{
    scroll-behavior: smooth;
}
/*--=====================================
----------start sub menu---------------
-========================================*/
.header-menu .sub-menu {
    height: auto;
    width: 350px; max-width: 100vw;
    background: #fff;
    box-shadow: 0 0 5px rgba(0,0, 0,0.2);
    position: absolute;
    text-align: left;
    top: 80px;
    transition: .3s;
    opacity: 0;
    left: 0;
    visibility: hidden;
    border-radius: 7px;
    padding: 15px 20px;
}
.header-area  .sub-menu ul li {
    display: block;
    margin-bottom: -40px;
}
.header-area .sub-menu ul li a {
    position: relative;
    color: #616161!important;
    margin: 5px 8px 5px -5px;
    font-weight: 500;
    font-size: 16px;
    padding-top: 10px;
}
.header-menu ul li:hover .sub-menu {
    top: 57px;
    opacity: 1;
    visibility: visible;
    z-index: 9;
}
.header-menu ul li{
    padding-bottom:40px;
}
.header-area .sub-menu ul li a::before {
    content: "";
    display: inline-block;
    position: relative; top: 3px;
    margin-right: 5px;
    background-image: url("/img/star-2.png");
    background-size: 16px 16px; background-repeat: no-repeat; background-position: center center;
    height: 16px; width: 16px;
    opacity: 0;
}
.header-area .sub-menu ul li a:is(:hover,.active) {
    color: #fd9100 !important;
    padding-left: 20px;
}

.header-area .sub-menu ul li a:is(:hover,.active)::before {
    opacity: 1;
    visibility: visible;
}
/*--==========================================
	start-header-area-------------------------
	-=========================================*/
.header-area {
    background: #fff;
}
.transparent .header-button a {
    background: transparent;
    border: 1px solid #fff;
    padding: 11px 35px;
}
.sticky-nav .header-button a {
    background: transparent;
    border: 1px solid #fff;
}
.transparent .header-button a:hover {
    background: #fff;
    color: #fd9100;
}
.transparent.sticky-nav .header-button a {
    color: #000;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 0;
    text-align: center;
    text-transform: capitalize;
    transition: all 0.5s;
    border-radius: 30px;
    margin-left: 2px;
    background: transparent;
    padding: 12px 36px;
    font-weight: 500;
    border: 1px solid #fff;
}
.header-area:before{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;
    background-color: rgba(17,26,58,0.4);
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    position: absolute;
    border-top: 1px dotted rgba(120, 125, 130, .8);
}
.header-menu ul {
    display: inline-block;
}
.header-logo a img {
    width: 75%;
}
.header-menu ul li {
    list-style: none;
    display: inline;
    position: relative;
}
.header-menu ul li a {
    display: inline-block;
    font-size: 18px;
    margin: 35px 12px;
    font-weight: 500;
    color: var(--blue);
    transition: .5s;
}
.header-menu ul li a:hover {
    color: #fd9100;
}
.sticky-nav .header-menu ul li a{
    color:#000;
}

.sticky-nav .header-menu .sub-menu ul li a{
    color:#000;
}
.header-menu ul li a i {
    opacity: 0.5;
    font-size: 15px;
}
.header-button {
    display: inline-block;
    margin-left: 8px;
}
.header-button a {
    color: #fff;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 0;
    text-align: center;
    text-transform: capitalize;
    transition: all 0.5s;
    border-radius: 30px;
    margin-left: 2px;
    background: #fd9100;
    padding: 13px 36px;
    font-weight: 500;
}
.header-button a:hover {
    background: #00247E;
    color: #fff;
}
.header-area.transparent {
    background-color: transparent;
    position: relative;
    margin-bottom: -102px;
    z-index: 22;
}
.transparent .header-menu ul li a {
    color: #fff;
}
.transparent.header-menu ul li a:hover{
    color: #fff!important;
}
.transparent .header-area .sub-menu ul li a:hover {
    margin-left: 30px;
    color: #fd9100;
}
.sticky-nav.header-area.transparent {
    background-color: #fd9100;
    position: relative;
    margin-bottom: -102px;
    z-index: 22;
}
.sticky-nav .header-button a {
    color: #fd9100;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 0;
    text-align: center;
    text-transform: capitalize;
    transition: all 0.5s;
    border-radius: 30px;
    margin-left: 2px;
    background: #fff;
    padding: 12px 36px;
    font-weight: 500;
}
.search-box .form-group input[type="text"],
.search-box .form-group input[type="search"]{
    position:relative;
    line-height:28px;
    padding:10px 50px 10px 25px;
    background:none;
    display:block;
    font-size:14px;
    width:200px;
    height:74px;
    color:#222222;
    font-weight:400;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
    background-color:#ffffff;
    border-radius:0px 50px 50px 0px;
}
/* ==========================================
start search Popup Css
=========================================*/
.search-popup{
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: 99999;
    margin-top: -540px;
    transform: translateY(-100%);
    background-color: rgba(0,0,0,0.90);
    -webkit-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition: all 1500ms cubic-bezier(0.860, 0.000, 0.070, 1.000); /* easeInOutQuint */
    -webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000); /* easeInOutQuint */
}
.search-popup{
    width: 100%;
}
.search-active .search-popup{
    transform: translateY(0%);
    margin-top: 0;
}
.search-popup .close-search {
    position: absolute;
    left: 0;
    right: 0;
    top: 75%;
    margin: 0 auto;
    margin-top: -200px;
    border-radius: 50%;
    text-align: center;
    background-color: #fd9100;
    width: 70px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    border-bottom: 3px solid #ffffff;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    opacity: 0;
    visibility: hidden;
    height: 70px;
    line-height: 70px;
    text-align: center;
}
.search-popup .close-search i{
    position: relative;
    font-size: 30px;
    color: #ffffff;
}
.search-active .search-popup .close-search{
    visibility: visible;
    opacity: 1;
    top: 50%;
    -webkit-transition-delay: 1500ms;
    -moz-transition-delay: 1500ms;
    -ms-transition-delay: 1500ms;
    -o-transition-delay: 1500ms;
    transition-delay: 1500ms;
}

.search-popup form{
    position: absolute;
    max-width: 700px;
    top: 50%;
    left: 15px;
    right: 15px;
    margin:-35px auto 0;
    transform: scaleX(0);
    transform-origin: center;
    background-color: #111111;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.search-active .search-popup form{
    transform: scaleX(1);
    -webkit-transition-delay: 1200ms;
    -moz-transition-delay: 1200ms;
    -ms-transition-delay: 1200ms;
    -o-transition-delay: 1200ms;
    transition-delay: 1200ms;
}

.search-popup .form-group{
    position:relative;
    margin:0px;
    overflow: hidden;
}
.search-popup .form-group input[type="text"],
.search-popup .form-group input[type="search"]{
    position:relative;
    display:block;
    font-size:18px;
    line-height: 50px;
    color:#000000;
    height:70px;
    width:100%;
    padding: 10px 30px;
    background-color: #ffffff;
    -webkit-transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
    transition:all 500ms ease;
    font-weight:500;
    text-transform:capitalize;
}
.search-popup .form-group input[type="submit"], .search-popup .form-group button {
    position: absolute;
    right: 30px;
    top: 0px;
    height: 70px;
    line-height: 70px;
    background: transparent;
    text-align: center;
    font-size: 24px;
    color: #000000;
    padding: 0;
    cursor: pointer;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    border: none;
}
.search-popup .form-group input[type="submit"]:hover,
.search-popup .form-group button:hover{
    color: #000000;
}
.search-popup input::placeholder,
.search-popup textarea::placeholder{
    color:#000000;
}
.search-popup .close-search.style-two{
    position: absolute;
    right: 25px;
    left: auto;
    color:#ffffff;
    width:auto;
    height:auto;
    top:25px;
    margin:0px;
    border:none;
    background:none !important;
    box-shadow:none !important;
    -webkit-transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
    transition:all 500ms ease;
}
.search-popup .close-search.style-two i{
    font-size:20px;
    color:#ffffff;
}
.header-src-btn {
    float: right;
    position: relative;
    margin-top: 35px;
}
.search-box-btn.search-box-outer {
    margin-left: 10px;
}
.search-box-btn.search-box-outer i {
    font-size: 13px;
    display: inline-block;
    background: #fd9100;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
}




/* =================================Start petech mission Area============================ */


/*====================================================
--<  .mission  Css -->
======================================================*/
.mission-section{
    padding: 100px 0px;
}

.mission-section .image-tham{
    position: relative;
}

.mission-section .image-tham img{
    width: 100%;
}

.mission-section .video-icon {
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    padding-top: 8px;
}
.mission-section .video-icon a {
    width: 100px;
    height: 100px;
    line-height: 100px;
    display: inline-block;
    text-align: center;
    border-radius: 100%;
    position: relative;
    color: #fd9100;
    -webkit-animation: ripple-white 1s linear infinite;
    animation: ripple-blue 1s linear infinite;
    -webkit-transition: .5s;
    background: #fff;
    font-size: 28px;
    z-index: 1;
}

@-webkit-keyframes ripple-blue {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.3), 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3);
        box-shadow: 0 0 0 0 rgba(255,255,255, 0.3), 0 0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3)
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255, 255, 255, 0.3), 0 0 0 30px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 20px rgba(255, 255, 255, 0.3), 0 0 0 30px rgba(255, 255, 255, 0)
    }
}

@keyframes ripple-blue {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.3), 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3);
        box-shadow: 0 0 0 0 rgba(255,255,255, 0.3), 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3)
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3), 0 0 0 30px rgba(255,255,255, 0);
        box-shadow: 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3), 0 0 0 30px rgba(255,255,255, 0)
    }
}

/*====================================================
--<  .planning-section  Css -->
======================================================*/
.planning-section{
    padding: 100px 0px;
    background-color: #f5f6f9;
}

.planning-section .title{
    text-align: center;
    margin-bottom: 60px;
}

.planning-section .title h4{
    font-size: 18px;
    color: #fd9100;
}

.planning-section .title h2{
    font-size: 35px;
    color: #232323;
}

.planning-section .title span{
    color: #fd9100;
}

.planning-section .planning-item .image img{
    width: 100%;
}

.planning-section .planning-item .text h3{
    font-size: 22px;
    color: #222222;
}

.planning-section .planning-item .text p{
    font-size: 16px;
    color: #616161;
}
/*====================================================
--<  .mission-section  Css -->
======================================================*/
.mission-section2{
    background: url('');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    padding: 100px 0px;
}

.mission-section2 .right-content{
    margin-left: 30px;
}

.mission-section2 .right-content h4{
    font-size: 16px;
    color: #fd9100;
    margin-top: 0px;
}

.mission-section2 .right-content h2{
    font-size: 40px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 20px;
}

.mission-section2 .right-content span{
    color: #fd9100;
}

.mission-section2 .right-content p{
    font-size: 15px;
    color: #f0f0f0;
}

.mission-section2 .right-content .icon p{
    font-size: 18px;
    color: #f0f0f0;
}

.mission-section2 .right-content .icon i{
    color: #fff;
    background-color: #fd9100;
    height: 20px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 30px;
    margin-right: 15px;
}

/*====================================================
--<  vission-section  Css -->
======================================================*/
.vission-section{
    background: url('');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    padding: 100px 0px;
}

.vission-section .left-content{
    margin-left: 30px;
}

.vission-section .left-content h4{
    font-size: 16px;
    color: #fd9100;
    margin-top: 0px;
}

.vission-section .left-content h2{
    font-size: 40px;
    color: #232323;
    font-weight: 600;
    margin-bottom: 20px;
}

.vission-section .left-content span{
    color: #fd9100;
}

.vission-section .right-content p{
    font-size: 15px;
    color: #616161;
}

.vission-section .left-content .icon p{
    font-size: 18px;
    color: #232323;
}

.vission-section .left-content .icon i{
    color: #fff;
    background-color: #fd9100;
    height: 20px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 30px;
    margin-right: 15px;
}

/*====================================================
--<  mission-brandn  Css -->
======================================================*/
.mission-brand{
    background-image: url('');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.mission-brand .mission-brand-slider{
    padding: 100px 0px;
}

.mission-brand .mission-brand .brand-image img{
    width: 100%;
}

.mission-brand .subscribe-box{
    background-image: url('');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 50px 50px;
    border-radius: 7px;
}

.mission-brand .subscribe-box .title h2{
    color: #fff;
    font-size: 35px;
    margin-bottom: 10px;
}

.mission-brand .subscribe-box .title p{
    color: #fff;
}

.mission-brand .subscribe-box .form-wraper{
    position: relative;
}

.mission-brand .subscribe-box .form-wraper input{
    padding: 28px 20px;
}

.mission-brand .subscribe-box .form-wraper input::placeholder{
    /* padding-left: 20px;
    margin-left: 30px; */
}

.mission-brand .subscribe-box .form-wraper .btn-submit{
    position: absolute;
}

.mission-brand .subscribe-box .form-wraper button{
    padding: 15px 40px;
    border: 0;
    background-color: #fd9100;
    border: 1px solid #fd9100;
    color: #fff;
    border-radius: 0px 5px 5px 0px;
    right: 0;
    top: 1px;
    transition: .5s;
    position: relative;
    transition: .5s;
    z-index: 1;
}

.mission-brand .subscribe-box .form-wraper button::before{
    position:absolute;
    content:"";
    left:0;
    top:0;
    height:100%;
    width:0%;
    background:#fff;
    transition:.5s;
    border-radius:5px;
    z-index:-1;
}

.mission-brand .subscribe-box .form-wraper button:hover::before{
    width:100%;
}
.mission-brand .subscribe-box .form-wraper button:hover{
    color: #fd9100;
    border: 2px solid #fd9100;
}

/*====================================================
--<  history  Css -->
======================================================*/


/*====================================================
--<  first-month-section  Css -->
======================================================*/
.first-month-section{
    position: relative;
    padding: 100px 0px 0px 0px;
}

.first-month-section .main-section{
    position: relative;
}

.first-month-section .main-section::after{
    position: absolute;
    content: "";
    width: 2px;
    background-color: #f4f4f4;
    right: 50%;
    transform: translateX(-50%);
    top: 15px;
    bottom: 0;
    z-index: -1;
}

.first-month-section .year h3{
    position: absolute;
    right: -44px;
    top: 0;
    font-size: 28px;
    background-color: #fd9100;
    color: #fff;
    height: 90px;
    width: 90px;
    line-height: 90px;
    text-align: center;
    border-radius: 44px;
}

.first-month-section .text-section{
    text-align: end;
    padding: 166px 35px 0px 0px;

}

.first-month-section .text-section h4{
    color: #fd9100;
    font-size: 16px;
    position: relative;
}

.first-month-section .text-section h4::before{
    position: absolute;
    right: -60px;
    top: 0;
    content: "";
    height: 25px;
    width: 25px;
    border-radius: 25px;
    background-color: #fd9100;

}

.first-month-section .text-section h2{
    font-size: 26px;
    color: #222222;
    margin: 20px 0px 15px 0px;
}

.first-month-section .text-section p{
    font-size: 16px;
    color: #616161;
}

.first-month-section .main-section .second-month-section{
    margin-top: 60px;
}

.first-month-section .main-section .second-month-section .image{
    margin-right: 35px;
    margin-top: 15px;
}

.first-month-section .main-section .second-month-section .image img{
    width: 100%;
}

.first-month-section .main-section .second-month-section .text-section{
    padding: 0;
    margin-left: 35px;
    text-align: start;

}

.first-month-section .main-section .second-month-section .text-section h4{
    color: #fd9100;
    font-size: 16px;
    position: relative;
}

.first-month-section .main-section .second-month-section .text-section h4::before{
    position: absolute;
    left: -64px;
    top: 0;
    content: "";
    height: 25px;
    width: 25px;
    border-radius: 25px;
    background-color: #fd9100;

}

.first-month-section .main-section .second-month-section .text-section h2{
    font-size: 26px;
    color: #222222;
    margin: 20px 0px 15px 0px;
}

.first-month-section .main-section .second-month-section .text-section p{
    font-size: 16px;
    color: #616161;
}




.first-month-section .main-section .third-month-section{
    margin-top: 60px;
    position: relative;
}

.first-month-section .main-section .third-month-section .image{
    margin-left: 35px;
    margin-top: 15px;
}

.first-month-section .main-section .third-month-section .image img{
    width: 100%;
}



.first-month-section .main-section .third-month-section .text-section{
    text-align: end;
    padding: 0;
    margin-right: 35px;

}

.first-month-section .main-section .third-month-section .text-section h4{
    color: #fd9100;
    font-size: 16px;
    position: relative;
}

.first-month-section .main-section .third-month-section .text-section h4::before{
    position: absolute;
    right: -60px;
    top: 0;
    content: "";
    height: 25px;
    width: 25px;
    border-radius: 25px;
    background-color: #fd9100;

}

.first-month-section .main-section .third-month-section .text-section h2{
    font-size: 26px;
    color: #222222;
    margin: 20px 0px 15px 0px;
}

.first-month-section .main-section .third-month-section .text-section p{
    font-size: 16px;
    color: #616161;
}

.first-month-section .main-section .third-month-section .year2 h3{
    position: absolute;
    left: -44px;
    bottom: 0;
    font-size: 28px;
    background-color: #fd9100;
    color: #fff;
    height: 90px;
    width: 90px;
    line-height: 90px;
    text-align: center;
    border-radius: 44px;
}



.first-month-section .main-section .forth-month-section{
    margin-top: 260px;
    position: relative;
}


.first-month-section .main-section .forth-month-section .year2 h3{
    position: absolute;
    right: -44px;
    top: -160px;
    font-size: 28px;
    background-color: #fd9100;
    color: #fff;
    height: 90px;
    width: 90px;
    line-height: 90px;
    text-align: center;
    border-radius: 44px;
}

.first-month-section .main-section .forth-month-section .image{
    margin-right: 35px;
    margin-top: 15px;
}

.first-month-section .main-section .forth-month-section .image img{
    width: 100%;
}

.first-month-section .main-section .forth-month-section .text-section{
    padding: 0;
    margin-left: 35px;
    text-align: start;

}

.first-month-section .main-section .forth-month-section .text-section h4{
    color: #fd9100;
    font-size: 16px;
    position: relative;
}

.first-month-section .main-section .forth-month-section .text-section h4::before{
    position: absolute;
    left: -64px;
    top: 0;
    content: "";
    height: 25px;
    width: 25px;
    border-radius: 25px;
    background-color: #fd9100;

}

.first-month-section .main-section .forth-month-section .text-section h2{
    font-size: 26px;
    color: #222222;
    margin: 20px 0px 15px 0px;
}

.first-month-section .main-section .forth-month-section .text-section p{
    font-size: 16px;
    color: #616161;
}


.first-month-section .main-section .forth-month-section .text-section2{
    padding: 0;
    margin-right: 35px;
    text-align: end;
    margin-top: 80px;

}

.first-month-section .main-section .forth-month-section .text-section2 h4{
    color: #fd9100;
    font-size: 16px;
    position: relative;
}

.first-month-section .main-section .forth-month-section .text-section2 h4::before{
    position: absolute;
    right: -60px;
    top: 0;
    content: "";
    height: 25px;
    width: 25px;
    border-radius: 25px;
    background-color: #fd9100;

}

.first-month-section .main-section .forth-month-section .text-section2 h2{
    font-size: 26px;
    color: #222222;
    margin: 20px 0px 15px 0px;
}

.first-month-section .main-section .forth-month-section .text-section2 p{
    font-size: 16px;
    color: #616161;
}


.history-brand{
    background-image: url('');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 100px;
}

.history-brand .subscribe-box{
    background-image: url('');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 50px 50px;
    border-radius: 7px;
}

.history-brand .subscribe-box .title h2{
    color: #fff;
    font-size: 35px;
    margin-bottom: 10px;
}

.history-brand .subscribe-box .title p{
    color: #fff;
}

.history-brand .subscribe-box .form-wraper{
    position: relative;
}

.history-brand .subscribe-box .form-wraper input{
    padding: 28px 20px;
}

.history-brand .subscribe-box .form-wraper input::placeholder{
    /* padding-left: 20px;
    margin-left: 30px; */
}

.history-brand .subscribe-box .form-wraper .btn-submit{
    position: absolute;
}

.history-brand .subscribe-box .form-wraper button{
    padding: 15px 40px;
    border: 0;
    background-color: #fd9100;
    border: 1px solid #fd9100;
    color: #fff;
    border-radius: 0px 5px 5px 0px;
    right: 0;
    top: 1px;
    transition: .5s;
    position:relative;
    transition:.5s;
    z-index:1;
}

.history-brand .subscribe-box .form-wraper button::before{
    position:absolute;
    content:"";
    left:0;
    top:0;
    height:100%;
    width:0%;
    background:#fff;
    transition:.5s;
    border-radius:5px;
    z-index:-1;
}

.history-brand .subscribe-box .form-wraper button:hover::before{
    width:100%;
}
.history-brand .subscribe-box .form-wraper button:hover{
    color: #fd9100;
    border: 2px solid #fd9100;
}

@media only screen and (max-width: 991px){
    .first-month-section .main-section::after{
        display: none;
    }
}

@media only screen and (max-width: 820px){
    .first-month-section .year h3 {
        right: 15px;
    }

    .first-month-section .text-section h4::before {
        right: -35px;
    }

    .first-month-section .main-section .second-month-section .text-section h4::before {
        left: -35px;
    }

    .first-month-section .main-section .third-month-section .text-section h4::before {
        right: -35px;
    }

    .first-month-section .main-section .forth-month-section .year2 h3 {
        right: 15px;
    }


    .first-month-section .main-section .forth-month-section .text-section h4::before {
        left: -35px;
    }
}











/*--==================================================-->
<!-----Start Busino----Slider-- Area ----->
==================================================-*/
.slider-area {
    background: url('');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 910px;
}
.slider-content h4 {
    color: #fd9100;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 12px 0px;
}
.slider-content h1 {
    color: var(--blue);
    font-size: 60px;
    margin: 0;
    line-height: 1.2;
}
.slider-content p {
    font-size: 18px;
    margin: 33px 0 47px;
    width: 55%;
}
.slider-area.style-nine .slider-content p {
    margin: 33px 0 31px;
}
.slider-button a {
    background: #fd9100;
    padding: 17px 34px;
    color: #fff;
    font-size: 17px;
    border-radius: 30px;
    display: inline-block;
    transition: .5s;
}
.slider-button a i {
    margin-left: 5px;
}
.slider-button a:hover{
    background:#00247e;
}
/*style-two*/
.slider-area.style-two {
    background: url('');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.slider-area.style-two .slider-content p {
    font-size: 18px;
    margin: 34px 0 38px;
    width: 55%;
}
.slider-area.style-three {
    background: url('');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.slider-area.style-three .slider-content p {
    margin: 33px 0 35px;
}
/*========home-two-slider========*/
.slider-area.style-four.slider-area {
    background: url('');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 886px;
}
.slider-area.style-five.slider-area {
    background: url('');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 886px;
}
.slider-area.style-four .bd-video-two {
    position: absolute;
    right: 0;
    top: 50%;
}
.slider-area.style-five .slider-content {
    text-align: center;
}
.slider-area.style-five .slider-button {
    display: inline-block;
    text-align: center!important;
    margin-right: 33px;
}
.slider-area.style-five .slider-content p {
    font-size: 18px;
    margin: 33px 0 48px;
    width: 56%;
    text-align: center;
    margin: auto;
}
.slider-area.style-four .slider-content p {
    font-size: 18px;
    margin: 33px 0 36px;
    width: 55%;
}
.slider-area.style-five .slider-content p {
    font-size: 18px;
    margin: 33px auto 46px;
    width: 56%;
    text-align: center;
}
/*style-three*/
.slider-area.style-six {
    background: url('');
    background-position: center;
    background-size: cover;
}
.slider-area.style-seven {
    background: url('');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 813px;
}
.slider-area.style-seven .slider-button a:hover{
    background: #00247e;
}
/*style-four*/
.slider-area.style-eight {
    background: url('');
    background-position: center;
    background-size: cover;
    height: 894px;
}
.slider-area.style-nine {
    background: url('');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 894px;
}
.bd-video-two {
    display: inline-block;
}
.slider-section {
    position: relative;
}
.slider-area.style-eight .slider-content p {
    margin: 33px 0 35px;
}
.appoinment-form-two {
    background: #fff;
    width: 425px;
    padding: 30px;
    right: 0;
    border-radius: 7px;
}
.change-appointment-position {
    position: absolute;
    top: 22%;
    right: 375px;
    margin: auto;
    z-index: 1;
}
.hero-appointment .container{
    max-width: 430px;
}
.appoinment-form-two .appoinment-content {
    padding: 15px 22px 1px;
    text-align: center;
}
.shape-thumb {
    position: absolute;
    left: 0px;
    bottom: 0;
}
.shape-thumb-inner {
    position: absolute;
    bottom: 0;
    left: 0px;
}
/*----------Skyty Menu--------*/
.sticky-nav {
    left: 0;
    margin: auto;
    top: 0;
    width: 100%;
    position: fixed!important;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
    -webkit-animation: 300ms running fadeInDown;
    animation: 300ms running fadeInDown;
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    transition: .5s !important;
    background: #fff;
    z-index: 333;
}
.stiky-logo {
    display: none;
}
.sticky-nav .stiky-logo {
    display: block;
}
.sticky-nav .main-logo {
    display: none;
}
#sticky-nav ul li a {
    color: #fff;
}
.header ul li a {
    color: #fff;
}
/* Mobile Menu */

.menu-toggle {
    display: none;
}
.mobile-menu-icon {
    display: none;
}
.mobile-menu-social-icon a {
    background: #fd9100;
    display: inline-block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #fd9100;
    border-radius: 50%;
    color: #fff;
    font-size: 15px;
    margin-left: 10px;
}
.mobile-menu-social-icon a:hover {
    color:#fd9100;
    background:transparent;
    border: 1px solid#fd9100;
}
.mobile-menu-social-icon {
    text-align: left;
    margin-left: 15px;
}
.menu-toggle{
    display: none;
}
.menu-toggle .open_bar,
.menu-toggle .close_bar {
    color: #000;
    font-size: 20px;
    font-weight: 900;
    right: 30px;
    position: absolute;
    top: 36px;
    cursor: pointer;
}
.menu-toggle .close_bar{
    display: none;
}
.menu-toggle.closee .open_bar{
    display: none !important;
}
.menu-toggle.closee .close_bar{
    display: block !important;
}
span.mobile-menu-icon {
    display: none;
}
/*============video-area===============*/
.slider-button {
    display: inline-block;
    /* float: left; */
    margin-right: 33px;
}
.video-icon {
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    padding-top: 8px;
}
.slider-video-icon a, .video-icon a {
    width: 60px;
    height: 60px;
    line-height: 60px;
    display: inline-block;
    text-align: center;
    border-radius: 100%;
    position: relative;
    color: #fff;
    -webkit-animation: ripple-white 1s linear infinite;
    animation: ripple-blue 1s linear infinite;
    -webkit-transition: .5s;
    background: #fd9100;
    font-size: 28px;
    z-index: 1;
}
.slider-video-icon a {
    color: #fff;
}
@-webkit-keyframes ripple-blue {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.3), 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3);
        box-shadow: 0 0 0 0 rgba(255,255,255, 0.3), 0 0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3)
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255, 255, 255, 0.3), 0 0 0 30px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 20px rgba(255, 255, 255, 0.3), 0 0 0 30px rgba(255, 255, 255, 0)
    }
}

@keyframes ripple-blue {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.3), 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3);
        box-shadow: 0 0 0 0 rgba(255,255,255, 0.3), 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3)
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3), 0 0 0 30px rgba(255,255,255, 0);
        box-shadow: 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3), 0 0 0 30px rgba(255,255,255, 0)
    }
}
.bd-video {
    display: inline-block;
}
/*--==================================================-->
	<!-----Start-dreamit-feature-Area----->
	==================================================-*/
.single-feature-box {
    padding: 25px 20px;
    position: relative;
    z-index: 1;
    transition: .5s;
    overflow: hidden;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #EFF2F7;
    margin-bottom: 30px;
}
.single-feature-box::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    height: 4px;
    width: 0%;
    transition: .5s;
    background: #fd9100;
    z-index: -1;
    opacity: 0;
}
.single-feature-box:hover:before{
    width: 100%;
    opacity: 1;
}
.single-feature-box:hover{
    transform: translateY(-15px);
}
.row.ba {
    position: relative;
    margin-top: -90px;
}
.feature-icon i {
    color: #fd9100;
    position: relative;
    line-height: 95px;
    text-align: center;
    font-size: 45px;
    display: inline-block;
    margin-right: 15px;
    height: 95px;
    width: 95px;
    background: #EFF2F7;
    border-radius: 50%;
}
.feature-title h2 {
    font-size: 20px;
    margin: 5px 0 14px;
}
/*--==================================================-->
	<!-----Start-dreamit-clean----about---Area ----->
	==================================================-*/
.dreamit-about-thumb img {
    width: 100%;
    position: relative;
}
.dreamit-single-about-counter {
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    background: #fff;
    height: 170px;
    width: 170px;
    text-align: center;
    animation: alltuchtopdown 1.5s ease-in-out 0s infinite alternate;
    animation-duration: 2.5s;
    display: inline-block;
    position: absolute;
    border-radius: 100%;
    top: 314px;
    left: 98px;
}
.about-counter-text-inner {
    position: relative;
    top: 40%;
    transform: translateY(-50%);
}
.about-counter-text-inner h1 {
    color: var(--blue);
    font-size: 33px;
    font-weight: 600;
}
.about-counter-title h3 {
    color: var(--blue);
    font-size: 20px;
    margin: 3px;
    font-weight: 600;
    text-transform: capitalize;
}

.dreamit-section-sub-title h5 {
    color: var(--blue);
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 700;
    margin: 30px 0 10px;
}
/*style-two*/
.dreamit-section-sub-title2 h5 {
    color: #fd9100;
    font-size: 16px;
    text-transform:capitalize;
    font-weight: 700;
    margin: 30px 0 10px;
    position: relative;
}
.dreamit-section-sub-title2::before {
    position: absolute;
    content: "";
    height: 3px;
    width: 70px;
    background: #fd9100;
    top: 50px;
    left: -19px;
}
.dreamit-section-main-title h2,
.dreamit-section-main-title h3 {
    font-size: 39px;
    font-weight: 800;
    margin-bottom: 0;
    line-height: 1.2;
    margin:0;
}
.dreamit-section-main-title h2 span {
    color: var(--blue);
}
.dreamit-about-content {
    margin-bottom: 8px;
}
.about-icon i {
    color: #fd9100;
    font-size: 33px;
    display: inline-block;
    margin-right: 18px;
}
.about-button a {
    background: #fd9100;
    color: #fff;
    padding: 12px 35px;
    font-size: 18px;
    display: inline-block;
    border-radius: 5px;
    transition: .5s;
}
.about-button a:hover {
    background: var(--blue);
    transform: skew(-10deg);
}

@keyframes alltuchtopdown{
    0%{
        -webkit-transform:rotateX(0deg) translateY(0px);
        -ms-transform:rotateX(0deg) translateY(0px);
        transform:rotateX(0deg) translateY(0px)
    }
    50%{
        -webkit-transform:rotateX(0deg) translateY(-20px);
        -ms-transform:rotateX(0deg) translateY(-20px);
        transform:rotateX(0deg) translateY(-20px)
    }
    100%{
        -webkit-transform:rotateX(0deg) translateY(0px);
        -ms-transform:rotateX(0deg) translateY(0px);
        transform:rotateX(0deg) translateY(0px)
    }
}

.alltuchtopdown {
    -webkit-animation: alltuchtopdown 1.5s ease-in-out 0s infinite alternate;
    animation: alltuchtopdown 1.5s ease-in-out 0s infinite alternate;
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
}

/*style-two-title*/
.appoinment-area.style-two .about-button a {
    background: #fd9100;
    color: #fff;
    padding: 16px 41px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 16px;
}
.appoinment-area.style-two .about-button a:hover {
    color: #fd9100;
    background: #fff;
    transform: skew(-0);
}
.dreamit-section-title.style-two {
    text-align: center;
}

/*style-two*/
.dreamit-about-area.style-two .dreamit-single-about-counter {
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    background: #fd9100;
    height: 155px;
    width: 224px;
    text-align: center;
    animation: alltuchtopdown 1.5s ease-in-out 0s infinite alternate;
    animation-duration: 2.5s;
    display: inline-block;
    position: absolute;
    border-radius: 5px;
    top: 60%;
    left: 98px;
}
.progress {
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem;
    width: 95%;
}
.progress-title h6 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
    color: var(--blue);
}
.progress-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #fd9100;
    transition: width .6s ease;
}
/*style-three*/
.dreamit-about-area.style-three .dreamit-about-content {
    margin-bottom: 10px;
}
/*========================================*/
/*-start-dreamit-service-area*/
/*========================================*/
.dreamit-service-area {
    background: #EFF2F7;
}
.dreamit-single-service-box {
    margin-bottom: 30px;
}
.dreamit-service-thumb{
    overflow: hidden;

}
.dreamit-service-thumb img {
    position: relative;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: .5s;
}
.svg-img {
    position: relative;

}
.svg-img svg.svg-white, .svg-img svg.svg-white {
    fill: #fd9100;
    bottom: 0;
    transition: 0.5s;
}
.svg-img svg, .svg-img svg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    width: 100%;
    transition: 0.5s;
}
svg.svg-gray {
    fill: #fff;
}
.dreamit-service-shape {
    text-align: center;

}
.dreamit-service-shape {
    box-shadow: 0 0 4px rgba(0,0,0,0.16);
    height: 95px;
    width: 95px;
    line-height: 95px;
    text-align: center;
    background: #fff;
    border-radius: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 32%;
}
.dreamit-single-service-box:hover .dreamit-service-shape{
    transform:rotateY(-360deg);
}
.service-content {
    background: #fff;
    padding: 16px 23px 24px;
    border-radius: 5px;
}
.service-title h2 {
    font-size: 20px;
    margin: 2px 0 30px 0;
    font-weight: 700;
    position: relative;
    transition: .5s;
}
.service-title h2:hover{
    color:  #fd9100;
}
.service-title h2::before {
    position: absolute;
    content: "";
    background: #fd9100;
    height: 3px;
    width: 40px;
    left: 0;
    top: 36px;
}
.dreamit-service-button a {
    background: #fd9100;
    padding: 9px 27px;
    border-radius: 30px;
    text-transform: capitalize;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    margin-top: 10px;
    transition: .5s;
}
.dreamit-single-service-box:hover .dreamit-service-thumb img{
    transform: scale(1.1);
}
.dreamit-service-shape{
    transition: .5s;
}
.dreamit-service-shape img {
    width: 57%;
}
.dreamit-single-service-box:hover .dreamit-service-button a{
    background: var(--blue);
}
.dreamit-single-service-box:hover .svg-img svg.svg-white{
    fill:var(--blue);
}
/*style-two*/
.style-two .dreamit-service-box {
    border: 1px solid #dcdfe2;
    padding: 48px 10px 16px 7px;
    background: #fff;
    border-radius: 4px;
    transition: .5s;
    position: relative;
    text-align: center;
    z-index: 1;
    margin-bottom: 30px;
}.style-two .dreamit-service-box:before{
     position: absolute;
     content: "";
     left: 0;
     bottom: 0;
     height:0px;
     width: 100%;
     background:#fd9100;
     transition: .5s;
     border-radius: 4px;
     z-index: -1;
 }

.style-two .dreamit-service-box:hover{
    transform:translateY(-8px);
}

.style-two .dreamit-service-content h2 {
    margin: 27px 0 15px;
    font-size: 20px;
}
.style-two .dreamit-service-box:hover:before{
    height: 100%;
}
.style-two .dreamit-service-box:hover .dreamit-service-icon::after{
    background: #fff;
}
.style-two .dreamit-service-box:hover .dreamit-service-icon::before{
    opacity: 0;
}
.style-two .dreamit-service-box:hover .dreamit-service-content h2{
    color:#fff;
}
.style-two .dreamit-service-box:hover .dreamit-service-icon::after{
    background:#fff;
}
.style-two .dreamit-service-box:hover .dreamit-service-icon img{
    filter: inherit;
}
.style-two .dreamit-service-box:hover .dreamit-service-content p{
    color: #fff;
}
.dreamit-service-content {
    padding: 0 19px 0 20px;
}
.dreamit-service-content h2 {
    font-size: 20px;
}
.dreamit-service-icon {
    display: inline-flex; justify-content: center; align-items: center;
    height: 80px;
    width: 80px;
    line-height: 80px;
    text-align: center;
    position: relative;
    z-index: 1;
}
.dreamit-service-icon::before {
    position: absolute;
    left: -12px;
    top: -10px;
    height: 100%;
    width: 100%;
    background: #EEEFF2;
    content: "";
    z-index: -1;
    border-radius: 5px;
    transition: .5s;
}
.dreamit-service-icon::after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: var(--background);
    content: "";
    z-index: -1;
    border-radius: 5px;
}
.dreamit-service-icon img {
    width: 52px;
    filter: brightness(0)invert(1);
}
.style-two .service-content {
    text-align: center;
}

.service-btn a {
    box-shadow: 0 0 4px rgba(0,0,0,0.20);
    height: 50px;
    width: 50px;
    color: #fd9100;
    font-size: 21px;
    background: #fff;
    display: inline-block;
    text-align: center;
    line-height: 50px;
    font-weight: 700;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -30px;
    opacity: 0;
    transition: .5s;
}
.dreamit-service-box:hover .service-btn a {
    transform: scale(1);
    opacity: 1;
}
/*style-two*/

/*style-three*/
.service-area.style-three .dreamit-service-box {
    border: 1px solid #dcdfe2;
    padding: 48px 10px 16px 7px;
    background: #fff;
    border-radius: 4px;
    transition: .5s;
    position: relative;
    text-align: center;
    z-index: 1;
    margin-bottom: 30px;
}
.service-area.style-three .dreamit-service-box:before{
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height:0px;
    width: 100%;
    background:#fd9100;
    transition: .5s;
    border-radius: 4px;
    z-index: -1;
}

.service-area.style-three .dreamit-service-box:hover{
    transform:translateY(-8px);
}

.service-area.style-three .dreamit-service-content h2 {
    margin: 27px 0 15px;
    font-size: 20px;
}
.service-area.style-three .dreamit-service-box:hover:before{
    height: 100%;
}
.service-area.style-three .dreamit-service-box:hover .dreamit-service-icon::after{
    background: #fff;
}
.service-area.style-three .dreamit-service-box:hover .dreamit-service-icon::before{
    opacity: 0;
}
.service-area.style-three .dreamit-service-box:hover .dreamit-service-content h2{
    color:#fff;
}
.service-area.style-three .dreamit-service-box:hover .dreamit-service-icon::after{
    background:#fff;
}
.service-area.style-three .dreamit-service-box:hover .dreamit-service-icon img{
    filter: inherit;
}
.service-area.style-three .dreamit-service-box:hover .dreamit-service-content p{
    color: #fff;
}
.service-area.style-three .dreamit-service-content {
    padding: 0 19px 24px 20px;
}
.dreamit-service-icon {
    display: inline-flex; justify-content: center; align-items: center;
    height: 80px;
    width: 80px;
    line-height: 80px;
    text-align: center;
    position: relative;
    z-index: 1;
}
.service-area.style-three .dreamit-service-icon::before {
    position: absolute;
    left: -12px;
    top: -10px;
    height: 100%;
    width: 100%;
    background: #EEEFF2;
    content: "";
    z-index: -1;
    border-radius: 5px;
}
.service-area.style-three .service-area.style-three .dreamit-service-icon::after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #fd9100;
    content: "";
    z-index: -1;
    border-radius: 5px;
}
.dreamit-service-icon img {
    width: 52px;
    filter: brightness(0)invert(1);
}
.service-area.style-three  .style-two .service-content {
    text-align: center;
}
.dreamit-service-content p {
    transition: .5s;
}
.service-btn a {
    box-shadow: 0 0 4px rgba(0,0,0,0.20);
    height: 50px;
    width: 50px;
    color: #fd9100;
    font-size: 21px;
    background: #fff;
    display: inline-block;
    text-align: center;
    line-height: 50px;
    font-weight: 700;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -30px;
    opacity: 0;
    transition: .5s;
}
.service-area.style-three .dreamit-service-box:hover .service-btn a {
    transform: scale(1);
    opacity: 1;
}
.dreamit-service-icon i {
    font-size: 35px;
    color: #fff;
}
.service-area.style-three .dreamit-service-box:hover .dreamit-service-icon i{
    color: #fd9100;
}
.service-area.style-three .dreamit-service-box {
    text-align: center;
}
/*style-four*/
.dreamit-service-area.style-four {
    background: url('');
    background-position: center;
    background-size: cover;
    background-color: #EFF2F7;
    background-repeat: no-repeat;
}
.dreamit-service-area.style-four .dreamit-single-service-box {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
}
.dreamit-service-area.style-four .dreamit-service-shape {
    height: 90px;
    width: 90px;
    box-shadow: 0 0 4px rgba(0,0,0,0.16);
    line-height: 90px;
    text-align: center;
    border-radius: 100%;
    background: #fff;
    /* margin: auto; */
    position: absolute;
    /* right: -37px; */
    left: 184px;
    /* margin: auto; */
    top: 36%;
}
.dreamit-service-area.style-four .dreamit-service-content {
    padding: 37px 0px 2px 0px;
}
.dreamit-service-area.style-four .dreamit-single-service-box:hover .dreamit-service-shape {
    transform: rotateY(360deg);
    background: #fd9100;
}
.dreamit-service-area.style-four .dreamit-single-service-box:hover .dreamit-service-thumb img {
    transform: scale(1.0);
}
.dreamit-service-area.style-four .dreamit-single-service-box:hover .dreamit-service-shape img{
    filter: brightness(0)invert(1);
}
.dreamit-service-area.style-four .dreamit-single-service-box:hover .service-button a i{
    color: #fff;
}
.dreamit-service-area.style-four .service-title h2 {
    margin: 2px 0 9px 0;
}
.dreamit-service-thumb img {
    border-radius: 10px;
}
.dreamit-service-area.style-four .dreamit-service-shape img {
    width: 52px;
}
.dreamit-service-area.style-four .service-title h2::before {
    display: none;
}
.service-button {
    position: relative;
    display: block;
    padding-top: 12px;
}
.service-button a {
    padding: 16px 22px;
    background: #F4F7FC;
    color: #202020;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    position: relative;
    display: block;
    transition: .3s;
    z-index: 1;
    overflow: hidden;
}
.service-button a i {
    margin-left: 7px;
}
.dreamit-single-service-box:hover .service-button a {
    background: #fd9100;
    color: #fff;
}
.dreamit-single-service-box:hover .service-button a::after {
    background: var(--blue);
}
.service-button:after {
    content: "";
    position: absolute;
    right: 20px;
    background: #fff;
    width: 29px;
    height: 2px;
    top: 59%;
    z-index: 1;
    transform: translateY(-50%);
}
.service-button a::before {
    content: '';
    width: 0;
    height: 0;
    border-width: 5px 0 5px 7px;
    border-style: solid;
    display: block;
    right: 13px;
    border-color: transparent transparent transparent #f5f5f5;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.service-button a::after {
    content: '';
    position: absolute;
    top: 0;
    right: -20px;
    width: 23%;
    height: 100%;
    z-index: -1;
    transform: skew(-33deg);
    padding-left: 20px;
    background: #fd9100;
    transition: .3s;
    z-index: -1;
}
.service-button a {
    color: #fd9100;
}
.service-button a i {
    color: #fd9100;
}
/*style-five*/
.service-area.style-three{
    background: url('');
    background-color: #EFF2F7;
    background-size: cover;
    background-position: center;
}
/*style-five*/
.service-area.style-five .dreamit-service-box {
    border: 1px solid #dcdfe2;
    padding: 48px 10px 16px 7px;
    background: #fff;
    border-radius: 4px;
    transition: .5s;
    position: relative;
    text-align: center;
    z-index: 1;
    margin-bottom: 30px;
}
.service-area.style-five .dreamit-service-box:before{
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height:0px;
    width: 100%;
    background:#fd9100;
    transition: .5s;
    border-radius: 4px;
    z-index: -1;
}
.service-area.style-five .dreamit-service-box:hover{
    transform:translateY(-8px);
}
.service-area.style-five .dreamit-service-box:hover:before{
    height: 100%;
}
.service-area.style-five .dreamit-service-box:hover .dreamit-service-icon::after{
    background: #fff;
}
.service-area.style-five .dreamit-service-box:hover .dreamit-service-icon::before{
    opacity: 0;
}
.service-area.style-five .dreamit-service-box:hover .dreamit-service-content h2{
    color:#fff;
}
.service-area.style-five .dreamit-service-box:hover .dreamit-service-icon::after{
    background:#fff;
}
.service-area.style-five .dreamit-service-box:hover .dreamit-service-icon img{
    filter: inherit;
}
.service-area.style-five .dreamit-service-box:hover .dreamit-service-content p{
    color: #fff;
}
.service-area.style-five .dreamit-service-box:hover .dreamit-service-icon i{
    color: #fd9100;
}
.service-area.style-five .dreamit-service-content h2 {
    font-size: 20px;
    margin: 25px 0 15px;
}
/*========================================*/
/*-start-dreamit-choose-us-area*/
/*========================================*/
.dreamit-choose-us-thumb img {
    border-radius: 5px;
}
.dreamit-choose-us-content {
    margin-bottom: 30px;
}
.choose-us-title h2 {
    font-size: 20px;
    margin: 0 0 15px 0;
    font-weight: 700;
}
.dreamit-choose-us-icon i {
    font-size: 52px;
    color: #fd9100;
    display: inline-block;
}
.dreamit-choose-us-icon {
    margin-right: 22px;
}
/*style-two*/

/*========================================*/
/*-start-dreamit-choose--area*/
/*========================================*/
.dreamit-why-choose-area {
    background: linear-gradient(rgb(47, 68,143,0.9), rgba(47,68, 143, 0.9)), transparent
    url('');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.dreamit-why-choose-area.style-two .dreamit-section-main-title h2 {
    color: #fff;
}
.dreamit-choose-content p {
    color: #fff;
    font-size: 18px;
}
/*style-three*/
.dreamit-choose-us-area.style-two .dreamit-section-text p {
    font-size: 15px;
    line-height: 1.5;
}
.dreamit-why-choose-area.style-three {
    background: none;
}
.dreamit-why-choose-area.style-three .dreamit-choose-content p {
    color: #6c757d;
}
.dreamit-team-area.style-two .row.bd {
    position: relative;
    margin-top: -177px;
}
.dreamit-choose-us-thumb img {
    width: 100%;
}
.style-two .dreamit-choose-us-content {
    margin-bottom: 18px;
}
/*========================================*/
/*-start-dreamit-team--area*/
/*========================================*/
.dreamit-single-team-member {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    transition: .5s;
}
.row.bd {
    position: relative;
    margin-top: -140px;
}
.dreamit-team-thumb{
    position: relative;
    overflow: hidden;
}
.dreamit-team-thumb img {
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.dreamit-team-thumb{
    position: relative;
    z-index: -1;
}
.dreamit-team-social-icon {
    background: var(--blue);
    width: 50%;
    left: 0;
    right: 0;
    margin: auto;
    padding: 10px 10px;
    border-radius: 5px;
    position: absolute;
    bottom: -44px;
    opacity: 0;
    transition: .5s;
    z-index: 1;
    text-align: center;
}
.dreamit-team-social-icon-inner a i {
    margin: 0 5px;
    color: #fff;
}
.dreamit-team-content {
    text-align: center;
    padding: 15px 17px 23px;
    position: relative;
}
.dreamit-team-title a h3 {
    font-size: 20px;
    margin-bottom: 5px;
    transition: .5s;
    color: #222;
}
.dreamit-team-title a h3:hover{
    color:  #fd9100;
}
.dreamit-team-thumb::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background: rgba(39, 59, 158, 0.83);
    height: 100%;
    width: 100%;
    transition: .5s;
    opacity: 0;
    border-radius: 5px;
}
.dreamit-single-team-member:hover{
    opacity: 1;
    box-shadow: 6px 5px 30px rgba(0,0,0,0.12);
}
.dreamit-single-team-member:hover .dreamit-team-social-icon {
    bottom: 0;
    opacity: 1;
    visibility: visible;
}
.dreamit-single-team-member:hover .dreamit-team-thumb:before{
    opacity: 1;
    box-shadow: 6px 5px 30px rgba(0,0,0,0.12);
}
.dreamit-team-title span {
    color: #fd9100;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
}
/*style-two*/
.dreamit-team-area.style-two .dreamit-team-content::before {
    position: absolute;
    content: "";
    bottom: 0;
    height: 3px;
    width: 100%;
    background: #fd9100;
    margin: auto;
    left: 0;
}
.dreamit-team-area.style-two .dreamit-single-team-member:hover {
    transform: translate(0,-15px);
}

.team-social-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -68px;
    background: #fff;
    padding: 8px;
    transition: .3s;
    padding: 10px 10px 22px 10px;
}
.dreamit-single-team-member:hover .team-social-icon {
    right:0;
}
.dreamit-team-area.style-two .dreamit-team-thumb::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background: rgba(12,90,219,.85);
    height: 100%;
    width: 100%;
    transition: .5s;
}
/*style-three*/
.dreamit-team-area.style-three .row.bd {
    position: relative;
    margin-top: 0;
}
.dreamit-team-area.style-three .team-social-icon {
    padding: 10px 4px 22px 8px;
}
.dreamit-team-area.style-three .dreamit-team-content::before {
    position: absolute;
    content: "";
    bottom: 0;
    height: 3px;
    width: 100%;
    background: #fd9100;
    margin: auto;
    left: 0;
}
.dreamit-team-area.style-three .dreamit-single-team-member:hover {
    transform: translate(0,-15px);
}
.dreamit-team-area.style-three .dreamit-team-title span {
    font-weight: 500;
}
.dreamit-team-area.style-three .dreamit-team-title a h3 {
    color: #222;
    font-weight: 700;
}
/*========================================*/
/*-start-dreamit-process-area*/
/*========================================*/
.process-number {
    text-align: center;
}
.process-number h2 {
    background: #fd9100;
    height: 115px;
    width: 115px;
    line-height: 100px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    z-index: 1;
    font-size: 50px;
    transition: .5s;
    font-weight: 500;
    margin: auto;
}
.process-number h2::after {
    position: absolute;
    content: "";
    background: #f1f3f8;
    left: 0;
    top: -10px;
    transition:.5s;
    height: 100%;
    width: 100%;
    opacity: 1;
    border-radius: 50%;
    z-index: -1;
}
.process-number h2:hover:after{
    background: var(--blue);

}
.process-number h2:hover{
    color: #fff;
}
.process-content {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
}
.process-content::after {
    position: absolute;
    content: "";
    right: -39px;
    top: -94px;
    height: 71px;
    width: 72px;
    transition: .5s;
}
.dreamit-process-single-box.style-two .process-content::after {
    display: none;
}
.process-title h2 {
    font-size: 20px;
    margin: 30px 0 23px;
    font-weight: 700;
    position: relative;
}
.process-title h2::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    margin: auto;
    background: #fd9100;
    transition: .5s;
    height: 3px;
    width: 30px;
    top: 36px;
}
/*========================================*/
/*-start-dreamit-pricing-area*/
/*========================================*/
.dreamit-single-pricing-box {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-bottom: 30px;
}

.dreamit-single-pricing-box {
    position: relative;
}
.pricing-top-bar {
    position: relative;
    background: #fd9100;
    padding-top: 30px;
    margin-bottom: 15px;
    padding-bottom: 118px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.pricing-top-bar::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    margin: auto;
}
.pricing-top-bar {
    text-align: center;
}
.pricing-title{
    position: relative;
}
.pricing-title::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    margin: auto;
    background: #fff;
    height: 4px;
    width: 30px;
    transition: .5s;
    top: 49px;
}
.pricing-title::after {
    position: absolute;
    content: "";
    left: 47px;
    right: 0;
    margin: auto;
    background: #fff;
    height: 4px;
    width: 4px;
    transition: .5s;
    top: 49px;
}
.pricing-title h3 {
    margin-bottom: 8px;
    color: #fff;
}
.price-dolor {
    font-size: 32px;
    position: relative;
    top: -30px;
}
.pricing-content-inner span.tk {
    font-size: 70px;
}
.pricing-content-inner span {
    color: #fff;
    font-weight: 600;
}
.pricing-thumb {
    text-align: center;
    border: 2px solid #fd9100;
    border-radius: 100%;
    height: 100px;
    width: 100px;
    line-height: 100px;
    background: #fff;
    margin-left: 127px;
    position: relative;
    margin-top: -135px;
}
.pricing-thumb img {
    width: 60px;
}
.pricing-body {
    padding: 42px 0 49px;
    text-align: center;
    background: #fff;
}
.pricing-body ul li {
    margin-bottom: 13px;
}
.pricing-content {
    text-align: center;
}
.pricing-button a {
    border: 2px solid #fd9100;
    color: #fd9100;
    font-size: 18px;
    padding: 12px 34px;
    font-weight: 400;
    border-radius: 30px;
    text-transform: capitalize;
    display: inline-block;
    transition: .5s;
    margin-top: 20px;
}
.dreamit-single-pricing-box:hover .pricing-button a{
    background: #fd9100;
    color: #fff;
}
.pricing-body ul li i {
    margin-right: 5px;
    color: #fd9100;
    font-size: 14px;
}
.pricing-body ul li {
    font-size: 16px;
    font-weight: 300;
}
.dreamit-single-pricing-box.style-two .pricing-body {
    padding: 19px 0 47px;
    text-align: center;
}
.dreamit-single-pricing-box.style-two .pricing-top-bar::before {

    background: url('');
    background-repeat: no-repeat;
    background-position: bottom;
}
.dreamit-single-pricing-box.style-two .pricing-thumb {
    margin-top: -115px;
}
.dreamit-single-pricing-box.style-three .pricing-top-bar::before {
    background: url('');
    background-repeat: no-repeat;
    background-position: bottom;
}
.pricing-button a.active {
    background: #fd9100;
    color: #fff;
}
/*style-two*/
.priching-area.style-two {
    background: #EFF2F7;
}
/*========================================*/
/*-start-dreamit-counter--area*/
/*========================================*/
.dreamit-counter-area {
    background: #e6e6e6;
    padding: 101px;
}
@media (max-width: 700px) {
    .dreamit-counter-area {
        padding: 30px 15px;
    }
}
.dreamit-counter-icon i {
    color: #fd9100;
    font-size: 50px;
    display: inline-block;
    margin-top: 12px;
}
.counter-text h3 {
    font-size: 34px;
    color: var(--darkblue);
    margin: 0;
    display: inline-block;
}
.counter-title h4 {
    color: #616161;
    font-size: 20px;
    letter-spacing: 0;
    margin: 0;
    font-weight: 500;
}
.dreamit-counter-icon {
    margin-right: 20px;
}
.counter-text span {
    font-size: 34px;
    text-transform: uppercase;
}
@media (max-width: 800px) {
    .counter-text span {
        font-size: 1.35rem;
    }
}
/*style-two*/
.dreamit-counter-area.style-two {
    background: url('');
    background-color: var(--blue);
    padding: 100px;
}
.style-two .counter-content {
    text-align: center;
}
.dreamit-counter-area.style-two .counter-text span {
    font-size: 45px;
    text-transform: uppercase;
}
.dreamit-counter-area.style-two .counter-text h1 {
    margin: 30px 0 15px;
}
.dreamit-counter-area.style-two .dreamit-counter-icon i {
    border: 2px dotted #fff;
    height: 90px;
    width: 90px;
    line-height: 90px;
    border-radius: 100%;
    font-size: 39px;
    color: #fff;
}
/*style-three*/

/*========================================*/
/*-start-testimonial-area*/
/*========================================*/
.testimonial-area {
    background-color: #fafafa;
}
.testimonial-content {
    box-shadow: 0 0 10px 0 rgba(0,0,0,.05);
    background: #fff;
    padding: 50px 30px 35px;
    border-radius: 5px;
    position: relative;
    margin-bottom: 30px;
}
.testimonial-icon i {
    color: #fd9100;
    font-size: 34px;
    opacity: 0.3;
    position: absolute;
    right: 13px;
    top: 12px;
}
.testimonial-content-text p {
    width: 91%;
}
.testimonial-content::before {
    position: absolute;
    border-color: #fff transparent transparent #fff;
    top: auto;
    height: 0;
    width: 0;
    left: 0;
    margin-left: 0;
    border-width: 15px;
    border-style: solid;
    bottom: -20px;
    content: "";
}
.testimonial-thumb {
    margin-right: 20px;
    margin-top: 8px;
}
.testimonial-thumb img {
    border: 2px solid #fff;
    border-radius: 100%;
    aspect-ratio: 1;
    width: 75px;
    object-fit: cover;
}
.testimonial-title h2 {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    color: var(--darkblue);
}
.testimonial-title span {
    color: #616161;
    font-weight: 500;
}
/*style-two*/
.testimonial-area.style-two .testimonial-title h2 {
    color: var(--blue);
}
.testimonial-area.style-two .testimonial-title span {
    color: #fd9100;
    padding-top: 5px;
    display: block;
}
/*style-three*/
.testimonial-area.style-three {
    background: url('');
    background-repeat: no-repeat;
    /* background-position: center; */
    background-color: #EFF2F7;
}
.testimonial-area.style-three .testimonial-title span {
    color: #fd9100;
}
.testimonial-area.style-three .testimonial-title h2 {
    color: var(--blue);
}
/*========================================*/
/*-start-case-study----area*/
/*========================================*/
.case-study-section-title-area {
    background: url('');
    background-color: var(--blue);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.dreamit-case-single-box {
    position: relative;
    transition: .5s;
    margin-bottom: 65px;
}
.dreamit-case-single-box {
    position: relative;
    transition: .5s;
    margin-bottom: 65px;
}
.row.mt {
    position: relative;
    margin-top: -276px;
}
.dreamit-case-single-box:hover .case-stydu-thumb::before{
    opacity: 1;
}
.dreamit-case-single-box:hover .case-study-content{
    margin-top: -120px;
}
.dreamit-case-single-box:hover .case-study-icon {
    opacity: 1;
    top: 50%;
}
.dreamit-case-single-box:hover .case-study-icon ul li {
    margin: 0 5px;
}
.case-stydu-thumb {
    position: relative;
    transition:.5s;
    overflow: hidden;
}
.case-stydu-thumb::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    transition: .5s;
    background: rgba(0,0,0,0.8);
    height: 100%;
    width: 100%;
    opacity: 0;
}
.case-stydu-thumb img {
    width: 100%;
}
.case-study-icon ul li {
    display: inline-block;
    margin: 0 20px;
    transition: .5s;
}
.case-study-icon {
    position: absolute;
    left: 0;
    right: 0;
    top: 45%;
    transform: translateY(-50%);
    opacity: 0;
    transition: .5s;
    text-align: center;
}
.case-study-icon ul li a i {
    height: 55px;
    width: 55px;
    background: #fd9100;
    line-height: 55px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    display: inline-block;

}
.case-study-content {
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);
    padding: 38px 14px 26px;
    text-align: center;
    width: 85%;
    background: #fff;
    transition: .5s;
    position: absolute;
    border-radius: 5px;
    transition: .5s;
    margin: auto;
    margin-top: -68px;
    left: 26px;
}
.case-title h3 {
    font-size: 20px;
    margin: 0 0 5px;
}
.case-content-text p {
    color: #fd9100;
    line-height: 1.2;
}
/*style-two*/
.case-study-section-title-area.style-two  {
    background: linear-gradient(rgb(18, 40,124,0.9), rgba(18, 40, 124, 0.9)), transparent url('');
    background-color: var(--blue);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
/*========================================*/
/*-start-appoinment--area*/
/*========================================*/
.appoinment-area {
    background: url('');
    padding: 99px 0 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.appoinment-form {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
    background: #fff;
    padding: 45px 49px 70px;
    text-align: center;
    margin-right: 55px;
    border-radius: 7px;
    position: relative;
    margin-bottom: -107px;
}
.appoinment-title h3 {
    font-size: 25px;
    margin: 14px 0 10px;
}
.appoinment-form-two button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border: 1px solid #e6e6e6;
}
.contact_area.style-two button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border: 1px solid #e6e6e6;
}
.dreamit-house-cleaning-area button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border: 1px solid #e6e6e6;
}
.select-your-service.form-box-input {
    width: 100%;
    padding: 16px 4px 16px 21px;
    border: 1px solid #e6e6e6;
    margin-bottom: 17px;
    margin-top: 16px;
    margin-bottom: 2px;
}
.dreamit-house-cleaning-area .appoinment-form{
    margin-bottom: 20px;
}

.appoinment-button button {
    width: 100%;
    padding: 16px 20px;
    border-radius: 5px;
    background: #fd9100;
    color: #fff;
    display: inline-block;
    transition: .5s;
    border: navajowhite;
}
.appoinment-button :hover{
    background: #00247E;
    color: #fff;
}
textarea:focus {
    border-color: transparent;
    box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
    background-color: rgba(255,255,255,0.7);
    outline: 0;
}
.dreamit-house-cleaning-thumb img {
    width: 100%;
}
/*========================================*/
/*-start-call-do-action--area*/
/*========================================*/
.dreamit-call-do-action-area {
    background: url('');
    /* background-position: center; */
    /* background-size: cover; */
    background-repeat: no-repeat;
    padding-top: 117px;
    padding-bottom: 50px;
}
.call-do-action-button a {
    background: #fd9100;
    padding: 17px 40px;
    border-radius: 30px;
    color: #fff;
    display: inline-block;
    transition: .5s;
    text-transform: capitalize;
    font-weight: 500;
}
.call-do-action-content p {
    margin: 6px 0 15px;
}
.call-do-action-button a:hover {
    background: #fff;
    color: #fd9100;
}
/*style-two*/
.dreamit-call-do-action-area.style-two .call-do-action-content p {
    margin: 0px 0 15px;
}
/*====================================================
		--<dreamit--portfoilio----Area---Css -->
======================================================*/
/* Portfolio Nav */
.portfolio_nav {
    margin-bottom: 45px;
}
.portfolio_menu ul {
    text-align: center;
    list-style: none;
}
.portfolio_menu ul li {
    display: inline-block;
    margin: 0 14px;
    cursor: pointer;
}
.portfolio_menu ul li a{
    display: block;
    color:#fff;
    text-transform:uppercase;
    position:relative;
    transition:.5s;
}
.portfolio_menu ul li a:before {
    position: absolute;
    content: "";
    left: 0;
    top: 24px;
    height: 2px;
    width: 0%;
    background: #fff;
    transition:.5s;
}
.portfolio_nav ul li {
    background: #f2f4ff;
    border: medium none;
    font-size: 18px;
    line-height: 1;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: capitalize;
    padding: 12px 40px;
    font-weight: 700;
    letter-spacing: 0;
    position: relative;
    margin: 0 3px;
    margin-bottom: 7px;
    display: inline-block;
    border-radius: 5px;
    /* color: gray; */
}
.portfolio_nav ul li:hover, .portfolio_nav ul li.current_menu_item {
    color: #fff;
    background: #fd9100;
}

.dreamit-single-portfolio {
    margin-bottom: 30px;
    overflow: hidden;
}
.dreamit-single-portfolio {
    margin-bottom: 30px;
    overflow: hidden;
}
.style-eleven .dreamit-single-portfolio {
    margin-bottom: 0;
    overflow: hidden;
}
.dreamit-portfolio-thumb {
    position: relative;
    z-index: 1;
}
.dreamit-portfolio-thumb img {
    width: 100%;
    transition: .5s;
    border-radius: 5px;
}
.portfolio_nav ul li {
    font-size: 16px;
    font-weight: 500;
    color: #6c757d;
    border: transparent;
}
.portfolio_nav ul li:hover {
    background: #fd9100;
    color: #fff;
}
.dreamit-forfolio-content {
    width: 80%;
    margin: auto;
    padding: 30px 20px 22px 20px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
}
.dreamit-forfolio-content::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: #fd9100;
    z-index: -1;
    border-radius: 5px;
}
.fortfolio-title h3 a {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
}
.dreamit-forfolio-content span {
    color: #fff;
}
.fortfolio-title h3 {
    margin: 0;
}
.fortfolio-addrees ul a i {
    background: #fff;
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 50%;
    color: #fd9100;
    margin-right: 5px;
    margin-bottom: 19px;
    transition: .5s;
}
.fortfolio-addrees ul a i:hover {
    background: #00247E;
    color: #fff;
}
.dreamit-forfolio-content {
    position: absolute;
    left: 0;
    right: 0;
    content: "";
    transform: translateY(-50%);
    top: 70%;
    z-index: 1;
    opacity: 0;
    transition: .5s;
}
.dreamit-single-portfolio:hover .dreamit-forfolio-content {
    opacity: 1;
    top: 50%;
}
.dreamit-single-portfolio:hover .dreamit-portfolio-thumb img{
    transform: scale(1.1);
}
.portfoliuo-information-area {
    background: #F6F6F6;
}

.portfolio-thumb img {
    width: 100%;
}
.portfolio-infor-title h2 {
    font-size: 21px;
}
.fortfolio-addrees ul li {
    display: inline-block;
}
.portfolio-social-inf ul li {
    display: inline-block;
    margin-right: 7px;
}
.portfolio-information ul li {
    border-bottom: 1px solid #ddd;
    padding: 7px 0;
    font-size: 18px;
    font-weight: 300;
    text-transform: capitalize;
}
.portfolio-information strong {
    font-weight: 500;
    font-size: 20px;
}
.portfolio-social-inf strong {
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
}
.portfolio-information ul li:last-child {
    border: none;
}
.portfolio-social-inf ul li a i {
    background: #fb239a;
    height: 35px;
    width: 35px;
    line-height: 35px;
    color: #fff;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    margin-top: 10px;
}
.pr-infor-title h2 {
    font-size: 27px;
}

/*========================================*/
/*-start-testimonial-area*/
/*========================================*/
.blog-area {
    background: #EFF2F7;
}
.dreamit-blog-single-box {
    transition: .5s;
    background: #fff;
    margin-bottom: 30px;
    overflow: hidden;
    margin-top: 15px;
    border-radius: 5px;
}
.dreamit-blog-single-box:hover{
    transform: translate(0,-15px);
    box-shadow: 0 10px 10px rgba(12,12,12,.16);
}
.dreamit-single-thumb{
    position: relative;
    overflow: hidden;
}
.dreamit-single-thumb img {
    width: 100%;
    position: relative;
    overflow: hidden;
}
.post-catagoris a {
    background: #fd9100;
    color: #fff;
    padding: 2px 22px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 19px;
    display: inline-block;

}
.post-catagoris a {
    position: absolute;
    content: "";
    top: 36px;
    left: 45px;
    overflow: hidden;
    display: block;
}
.dreamit-blog-content {
    padding: 25px 30px 25px;
}
.blog-meta-box a i {
    color: #fd9100;
    font-size: 12px;
    margin-right: 6px;
}
.blog-meta-box span {
    color: #616161;
    text-transform: uppercase;
    font-size: 14px;
    display: inline-block;
    font-weight: 400;
    margin-right: 13px;
    transition: .5s;
}
.blog-meta-box span:hover{
    color: #fd9100;
}
.blog-meta-box span i {
    color: #fd9100;
    margin-right: 6px;
    font-size: 12px;
}
.blog-title h2 {
    color: var(--blue);
    font-size: 22px;
    font-weight: 800;
    margin-bottom: 0;
}
.blog-title h2 a {
    color: var(--blue);
    font-weight: 800;
}
.blog-content-text p {
    margin: 16px 0 15px;
    font-size: 16px;
}
.dreamit-blog-button a {
    color: var(--blue);
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: underline !important;
    font-weight: 500;
    display: inline-block;
}
/*===============owl-dots===========*/

.owl-dots {
    order: -1;
    display: flex; gap: 2px;
    justify-content: center;
    margin-top: 15px;
    position: relative; z-index: 2;
}
.owl-dot {
    height: 4px;
    width: 25px;
    background: #b4b4b4;
    opacity: .65;
}
.case-list element {
    padding: 0 135px;
}
.case-list .owl-dot {
    margin-top: 37px;
    text-align: center;
}
.owl-carousel .owl-item img {
    width: 75px !important;
}
.owl-dot.active {
    opacity: 1;
}
.testimonials_list2 .owl-dot {
    height: 5px;
    width: 35px;
    background: #bfcadc;
    margin: 15px 6px 9px;
    border-radius: 5px;
    margin-top: 5px;
    text-align: center;
}
.brand_list.owl-carousel {
    display: flex; flex-direction: column-reverse;
}
.owl-nav {
    position: relative;
    display: flex; gap: .5rem; justify-content: flex-end;
}
.owl-next, .owl-prev {
    height: 30px;
    width: 30px;
    line-height: 60px;
    display: flex; justify-content: center; align-items: center;
    background: #fd9100;
    transition: .5s;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    top: -347px;
    color: #fff;
    font-size: 16px;
}
.blog_list .owl-next {
    right: -43px;
}
.blog_list .owl-prev {
    left: -47px;
}
.blog_list:hover .owl-prev{
    opacity: 1;
}
.blog_list:hover .owl-next{
    opacity: 1;
}
.owl-prev:hover{
    background:#121B51;
    color: #fff;
}
.owl-next:hover{
    background:#121B51;
    color: #fff;
}

/*===================================*/
/*----------breatcome-area-----*/
/*===================================*/
.breatcome-area {
    background: url('');
    height: 450px;
    background-position: center;
}
.breatcome-content-title h1 {
    margin: 42px 0 32px;
}
.breatcome-content-text ul li i {
    margin: 0 10px 0 10px;
    color: #fff;
    font-size: 11px;
}
.breatcome-content-text ul li a {
    color: #fff;
    text-transform: uppercase;
}
.breatcome-content-text ul li {
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
}
.blog-area.style-three {
    background: #F6F6F6;
}
.blog-area.style-three .dreamit-blog-single-box {
    transition: .5s;
    background: #fff;
    margin-bottom: 30px;
    overflow: hidden;
    margin-top: 15px;
    border: 1px solid #e6e6e6;
    border-radius: 7px;
}
.blog-area.style-three .dreamit-blog-single-box:hover {
    transform:none;
    box-shadow: 0 27px 32px rgba(12,12,12,.16);
    border-color: transparent;
}
.blog-area.style-three .blog-title h2 a:hover {
    color: #fd9100;
}
.page-number ul li {
    display: inline-block;
}
.page-number ul li a {
    box-shadow: 0 0 5px rgba(0,0,0,0.20);
    height: 40px;
    width: 40px;
    display: inline-block;
    line-height: 40px;
    text-align: center;
    margin-right: 7px;
    font-size: 16px;
    font-weight: 500;
    color: #9990b8;
    transition: .5s;
}
.page-number ul li a.active {
    background: #fd9100;
    color: #fff;
}
.page-number ul li a:hover{
    background:#fd9100;
    color: #fff;
}
.blog-area.style-four .dreamit-blog-single-box {
    transition: .5s;
    background: #fff;
    margin-bottom: 30px;
    overflow: hidden;
    margin-top: 0px;
}
.blog-area.style-four .dreamit-blog-single-box:hover {
    transform: translate(0);
    box-shadow: 0 27px 32px rgba(12,12,12,.16);
}
.blog-area.style-four .dreamit-single-thumb img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
/*=======sidber-box=========*/
/*============sidber-box=========*/
.sidebar-box {
    box-shadow: 0 0 5px rgba(0,0,0,0.10);
    padding: 19px 32px 30px;
    margin-bottom: 30px;
    background: #fff;
}
.sidebar-search input {
    border: 1px solid #ced4da;
    color: #616161;
    height: 60px;
    border-radius: 8px;
    width: 100%;
    position: relative;
}
.sidebar-search button {
    color: #616161;
    font-size: 18px;
    position: absolute;
    right: 0;
    top: 58px;
    width: 37%;
    background: transparent;
    border: transparent;
}
.sidebar-title h2 {
    font-size: 25px;
}
.sidebar-category ul li {
    border-top: 1px solid #E8E8E8;
    padding: 15px 0;
}
.dreamit-section-bar {
    background: #aec6ef;
    height: 3px;
    width: 90px;
    border-radius: 30px;
    position: relative;
    margin-top: 16px;
}
.dreamit-section-bar::before {
    content: '';
    position: absolute;
    left: 0;
    top: -2.7px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #fd9100;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: MOVE-BG;
    animation-name: MOVE-BG;
}
@-webkit-keyframes MOVE-BG {
    from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    to {
        -webkit-transform: translateX(88px);
        transform: translateX(88px);
    }
}

@keyframes MOVE-BG {
    from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    to {
        -webkit-transform: translateX(88px);
        transform: translateX(88px);
    }
}
.sidebar-search-box {
    box-shadow: 0 0 5px rgba(0,0,0,0.10);
    padding: 18px 32px 29px;
    margin-bottom: 30px;
    background: #fff;
    border-radius: 5px;
}
.sidebar-category ul li a {
    color: #616161;
    font-weight: 400;
    transition: .5s;
}
.sidebar-category ul li a:hover {
    color: #fd9100;
}
.sidebar-cloud a {
    border: 1px solid #e6e6e6;
    padding: 5px 10px;
    display: inline-block;
    color: #616161;
    margin: 4px 5px 5px 1px;
    border-radius: 5px;
    transition: .5s;
}
.sidebar-cloud a:hover {
    background: #fd9100;
    color: #fff;
}
.sidebar-search {
    padding: 20px 0 10px;
}
.sidebar-archives ul li {
    border-top: 1px solid #E8E8E8;
    padding: 15px 11px 15px 0px;
}
.sidebar-archives ul li a {
    color: #616161;
    font-weight: 400;
    transition: .5s;
}
.sidebar-archives a:hover {
    color: #fd9100;
}
.resent-iteam.d-flex {
    border-bottom: 1px solid #E8E8E8;
    padding-top: 0px;
    overflow: hidden;
    margin-bottom: 1px;
    padding-bottom: 5px;
}
.sidebar-thumb-content {
    padding: 0 2px 0 0;
}
.sidebar-thumb-title a h2 {
    font-size: 18px;
}
.resent-iteam.d-flex:last-child {
    border: none;
}
.sidebar-thumb-title a h2:hover {
    color: #fd9100;
}
.sidebar-thumb-title span {
    color: #fd9100;
}
.block-quoet {
    background: #f6f6f6;
    padding: 30px 30px 25px;
    color: #082680;
    font-size: 19px;
    border-left: 5px solid #fd9100;
}
.block-quoet-thumb img {
    width: 100%;
}
.block-quoet p {
    font-size: 19px;
    font-weight: 500;
}
.block-quoet-body ul li {
    margin-left: 12px;
}
.dreamit-post-tag ul li {
    display: inline-block;
    margin-left: 7px;
}
.dreamit-post-tag ul li a {
    background: #f4f7fc;
    padding: 9px 22px;
    border-radius: 3px;
    margin-right: 2px;
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
    transition: .5s;
    text-transform: uppercase;
}
.dreamit-blog-social ul li {
    display: inline-block;
}
.dreamit-blog-social ul li a i {
    border: 1px solid #f4f7fc;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    color: #082680;
    border-radius: 5px;
    transition: .5s;
    display: inline-block;
    border-radius: 5px;
}
.dreamit-blog-social ul li a i:hover{
    background:  #fd9100;
    color: #fff;
}
.dreamit-post-tag ul li a:hover {
    background: #fd9100;
    color: #fff;
}
.dreamit-single-blog-bottom {
    border-top: 1px solid #eee;
    margin-top: 28px;
}
.block-quoet-body ul li i {
    color: #fd9100;
    margin-right: 6px;
}
/*style-four*/
.blog-area.style-four .blog-title h2 {
    color: var(--blue);
    font-size: 35px;
    font-weight: 500;
    margin: 17px 0 auto;
}
.blog-area.style-four .blog-title h2 a {
    font-weight: 600;
}
.blog-area.style-four .dreamit-blog-content {
    padding: 25px 43px 40px;
}
.house-content-inner.two {
    margin-bottom: 15px;
}
/*--==========================================
		Start------cantact----area------------
	-=========================================*/
.form_box input {
    width: 100%;
}
.contact_title h3 {
    font-size: 24px;
}
.contact_from.style-two .form_box.mb-10 {
    margin-top: -14px;
}
.quote_btn.text_center button {
    background: #fd9100;
    color: #fff;
    padding: 13px 28px;
    display: inline-block;
    transition: .5s;
}
.quote_btn.text_center button:hover {
    background: #00247E;
    color: #fff;
}
/*style-three*/
.contact_area.style-three {
    background: #F4F8FD;
}
.contact_area.style-three.pt-60.pb-70 {
    background: #F4F8FD;
}
.contact_area.style-three .quote_btn.text_center {
    text-align: center;
}
.dreamit-contact-content {
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: #DDDDDD;
    padding: 20px 20px 30px 20px;
    background: #fff;
    margin-bottom: 10px;
}
.contact-title h3 {
    color: var(--blue);
    font-size: 20px;
    margin: 8px 0 3px 0;
}
.contact-icon i {
    color: #fd9100;
    font-size: 42px;
    display: inline-block;
    margin: 20px 20px 0 4px;
}

/*call-do-action-style-two*/
.call-do-action.style-two {
    background: #fd9100;
    padding: 44px 0 20px;
}
.call-do-action.style-two .dreamit-section-main-title h3 {
    font-size: 35px;
}
.call-do-action.style-two .call-do-content-text p {
    font-size: 20px;
    color: #fff;
}
.call-do-action.style-two .call-do-button a {
    background-color: #fff;
    color: #fd9100;
    padding: 12px 43px 12px;
    border-radius: 5px;
    display: inline-block;
    transition: .5s;
    font-size: 18px;
}
.call-do-action.style-two .call-do-button {
    float: right;
}
.call-do-action.style-two .call-do-button a:hover {
    background: var(--blue);
    color: #fff;
    transform: skew(-10deg);
}

/*--==========================================
		Start------Footer-----area------------
	-=========================================*/
.footer-area {
    background: var(--blue);
    padding-top: 83px;
    background-size: cover;
}
.dreamit-footer-content p {
    color: #f4f6fe;
    margin-top: 32px;
    font-size: 16px;
}
.dreamit-footer-social-address ul {
    list-style: none;
}
.dreamit-footer-social-address ul li {
    display: inline-block;
}
.dreamit-footer-social-address ul li a i {
    font-size: 16px;
    display: block;
    margin-right: 16px;
    border-radius: 3px;
    transition: .5s;
    color: #fff;
}
.dreamit-footer-social-address ul li a i:hover {
    color: #fd9100;
}
.footer-content {
    position: relative;
    margin-top: -15px;
}
.footer-title h2 {
    font-size: 22px;
    color: #f8f9fe;
    margin-bottom: 35px;
}
.footer-menu ul li {
    list-style: none;
}
.footer-menu ul li a {
    font-size: 16px;
    color: #f8f9fe;
    display: inline-block;
    position: relative;
    margin-left: 17px;
    margin-bottom: 15px;
    font-weight: 500;
    transition: .3s;
}
.footer-menu ul li a::before {
    position: absolute;
    content: "";
    left: -23px;
    top: 4px;
    background-image: url("/img/star.png");
    background-size: 16px 16px; background-repeat: no-repeat; background-position: center center;
    height: 16px;
    width: 16px;
    transition: .5s;
}
.footer-menu ul li a:hover {
    color: #fd9100;
}
.footer-content.pt-20 p {
    color: #f4f6fe;
    font-size: 16px;
}
.text-footer p {
    color: #e3e3e3;
}
.footer-social-icon i {
    color: #c6ceec;
    display: inline-block;
    margin-right: 11px;
}
.row.ab {
    border-top: 1px solid rgba(255,255,255,.23);
    padding: 18px 0 10px;
}
/*=================Subscribe-Area============*/
/*===========================================*/
input.subscribe-mail-box {
    background: #F6F6FC;
    border: 1px solid #ddd;
    padding: 12px 10px;
    width: 100%;
    border-radius: 5px;
}
input.subscribe-mail-box:focus{
    outline:0;
}
.subscribe-area {
    position: relative;
}

button.subscribe-button button {
    color: #fff;
}
.subscribe-area button {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 10px 14px;
    background: #fd9100;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    border-radius: 5px;
    border-color: #fd9100;
    margin: auto;
    bottom: 0;
    border: 1px solid#fd9100;
}

.subscribe-area input:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
}
button:focus {
    outline-color: transparent;
}

/* placeholder-area----*/
.subscribe-area input::-moz-placeholder {
    color: #a0a1a2;
    font-size: 17px;
}
.subscribe-area ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a0a1a2!important;
    font-size: 16px;
    font-weight:400;
}
.subscribe-area ::-moz-placeholder {
    /* Firefox 19+ */
    color: #a0a1a2!important;
    font-size: 16px;
    font-weight:400;
}
.subscribe-area :-ms-input-placeholder {
    /* IE 10+ */
    color: #fff !important;
    font-size: 16px;
    font-weight:400;
}
.subscribe-area :-moz-placeholder {
    /* Firefox 18- */
    color: #a0a1a2!important;
    font-size: 16px;
    font-weight:400;
}

.copy-right-text p {
    font-size: .8rem;
    color: #f8f9fe;
    margin-top: 1px;
}
.footer-text-menu {
    text-align: right;
}
.footer-text-menu ul li {
    list-style: none;
    display: inline-block;
}
.footer-text-menu ul li a {
    display: inline-block;
    margin-left: 19px;
    color: #f8f9fe;
}
/*----------To Top---*/
#to-top {
    position: fixed;
    bottom: 13px;
    color: #fff;
    right: 54px;
    font-size: 24px;
    width: 60px;
    height: 60px;
    line-height: 50px;
    text-align: center;
    background: #fd9100;
    border: none;
    cursor: pointer;
    display: none;
    z-index: 99999;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    border-radius: 50%;
}

/*Loader*/

.loader-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: .8s 1s ease;
    z-index: 666;
}
.loader {
    position: relative;
    display: block;
    z-index: 201;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px;
    border-radius: 50%;
    transition: all 1s 1s ease;
    border: 3px solid transparent;
    border-top-color: #3977bc;
    -webkit-animation: spin 1.5s linear infinite;
    -moz-animation: spin 1.5s linear infinite;
    -o-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
.loader:before {
    position: absolute;
    content: '';
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-left-color: #fd9100;
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    -o-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
.loader:after {
    position: absolute;
    content: '';
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-right-color: #3977bc;
    -webkit-animation: spin 2.5s linear infinite;
    -moz-animation: spin 2.5s linear infinite;
    -o-animation: spin 2.5s linear infinite;
    animation: spin 2.5s linear infinite;
}
/** Here comes the Magic **/

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader-wrapper .loder-section {
    position: fixed;
    top: 0;
    width: 50%;
    height: 100%;
    background: #fbfbfb;
    z-index: 2;
}
.loader-wrapper .loder-section.left-section {
    left: 0;
    transition: 1s 1.4s ease;
}
.loader-wrapper .loder-section.right-section {
    right: 0;
    transition: 1s 1.4s ease;
}

/** When page loaded **/
.loaded .loder-section.left-section {
    left: -100%;
}
.loaded .loder-section.right-section {
    right: -100%;
}
.loaded .loader-wrapper {
    visibility: hidden;
}
.loaded .loader {
    top: -100%;
    opacity: 0;
}



/*particles js*/
div#particles {
    position: relative;
}

#webcoderskull {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 20px;
    width: 100%;
}
div#particles .slider-area {
    background: inherit;
}